import Vue from 'vue'
import App from './App.vue'
import Router from '@/router/routes.js'
import Mixins from '@/mixins/mixins.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueMq from 'vue-mq'
import VueMeta from 'vue-meta'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSmoothScroll from 'vue2-smooth-scroll'
import VueGtag from "vue-gtag";

import '@/assets/style/scss/_global.scss'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(VueMeta)

Vue.use(VueMq, {
  breakpoints: {
    phoneS: 370,
    phone: 500,
    tablet: 1024,
    laptop: 1250,
    laptopL: 1400,
    desktop: Infinity,
  }
})
 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBcRiuky3b1Rl0eCZJppAYB2dJ_9RSpPh8',
  },
})

Vue.use(VueSmoothScroll, { 
  updateHistory: false,
})

Vue.use(VueGtag, {
  config: { id: "G-QSQLCCJFVQ" }
}, Router);

Vue.mixin(Mixins)

new Vue({
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
  router: Router,
}).$mount('#app')


