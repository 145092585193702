export default{
    methods: {
        fetch(route, subroute=null, param=null){
            let full_route = "https://api.vakumpumpe.com/api/" + route;
            if(subroute){
                full_route += "/" + subroute;
            }
            if(param){
                full_route += "/" + param;
            }

            return this.$http.get(full_route)
        }
    }
}
