<template>
    <main>


        <div class="landing flex-row">

            <div class="left flex-column"> 
                <img src="../../assets/img/logo/logo-busch.png" alt="Busch" class="noselect">
                <h2>Ovlašćeni uvoznik, distributer i serviser Busch vakuum pumpi</h2>
            </div>

            <div class="right flex-column">

                <div class="akcija flex-column">
                    <i class="fas fa-toolbox"></i>
                    <h3>Besplatan pregled vakuumskih postrojenja</h3>
                    <div class="akcija-item-info flex-column">
                        <p>Za više informacija, kontaktirajte nas.</p>
                        <router-link to="/kontakt/" class="btn btn-light">Kontakt</router-link>
                    </div>
                </div>

                <img src="../../assets/img/home/home-top1.jpg" alt="">

            </div>

        </div>      


        <div class="landing-items flex-row">

            <div class="item flex-column">                        
                <div class="hr"></div>
                <i class="fas fa-money-check"></i>
                <h2>Prodaja vakuum pumpi</h2>
                <p>Dostupni svi modeli, svih serija, Busch vakuum pumpi.</p>
                <router-link to="/prodaja/" class="btn btn-dark">Više informacija</router-link>
            </div>

            <div class="item flex-column">                        
                <div class="hr"></div>
                <i class="fas fa-wrench"></i>
                <h2>Servis vakuum pumpi</h2>         
                <p>Preko 20 godina iskustva sa servisom vakuum pumpi.</p>
                <router-link to="/servis/" class="btn btn-dark">Više informacija</router-link>
            </div>

            <div class="item flex-column">                        
                <div class="hr"></div>
                <i class="fas fa-cog"></i>
                <h2>Prodaja rezervnih delova</h2>        
                <p>Svi rezervni delovi, vodećih proizvođača vakuum pumpi.</p>
                <router-link to="/rezervnidelovi/svi-delovi/" class="btn btn-dark">Pronađite</router-link>
            </div>

        </div>


    </main>
</template>

<script>
export default {   

    computed: {
        isLargeScreen(){
            if(this.$mq === 'desktop' || this.$mq === 'laptopL' || this.$mq === 'laptop' )
                return true

            return false
        }
    },
}
</script>

<style lang="scss" scoped>
    main{
        background-color: #ffffff;
        width: 100%;

        .landing{
            width: 100%;
            min-height: calc(100vh - 120px - 8vh);

            .left{
                justify-content: center;
                width: 50%;
                height: 100%;
                padding: 2rem;
                box-sizing: border-box;

                img{
                    height: 12rem;
                }

                h2{
                    text-align: center;
                    color: $dark-1;
                    font-size: 2rem;
                    width: 90%;
                    margin-top: 2rem;
                }
            }

            .right{
                justify-content: flex-end;
                width: 50%;
                height: 100%;
                padding: 2rem;
                box-sizing: border-box;

                .akcija{
                    position: relative;
                    background-color: $dark-2;
                    color: $light-1;
                    width: 80%;
                    margin-bottom: 2rem;
                    padding: 1.5rem;
                    box-sizing: border-box;
                    border-radius: 3px;
                    justify-content: center;

                    i{
                        z-index: 2;
                        color: $primary-orange;
                        font-size: 2.3rem;
                    }

                    h3{
                        z-index: 2;
                        text-align: center;
                        color: $light-1;
                        font-size: 1.8rem;
                        margin-bottom: 0.5rem;
                    }

                    .akcija-item-info{
                        z-index: 2;

                        p{
                            color: $light-2;
                            text-align: center;
                            font-size: 1.1rem;
                            font-weight: 600;
                            margin-bottom: 1rem;
                        }
                    }
                    
                }

                img{
                    width: 90%;
                    height: 50%;
                }
            }
        }        

        .landing-items{
            background-color: $dark-2;
            width: 100%;
            padding: 0 3rem 2rem 3rem;
            box-sizing: border-box;
            border-radius: 0 0 3px 3px;
            align-items: center;
            justify-content: space-between;

            .item{
                position: relative;
                background-color: $light-2;
                justify-content: center;
                width: 30%;
                height: 100%;
                margin-top: 2rem;
                padding: 3rem 1.5rem;
                box-sizing: border-box;
                border-radius: 3px;

                .hr{
                    position: absolute;
                    top: 0;
                    background-color: $dark-1;
                    width: 25%;
                    height: 3px;
                    margin: 0 auto;
                }
                
                i{
                    font-size: 2rem;
                    color: $primary-orange;
                }

                h2{
                    color: $dark-1;
                    font-size: 1.5rem;
                    text-align: center;
                }

                p{
                    color: $dark-2;
                    font-size: 1.1rem;
                    font-weight: 600;
                    text-align: center;
                    margin-top: 0.3rem;
                }

                .btn{
                    cursor: pointer;
                    text-align: center;
                    margin-top: 0.7rem;
                }
            }
        }
    }
    


    //**********RESPONSIVE************//
    .laptopL{
        main{
            .landing{

                .left{

                    img{
                        height: 10rem;
                    }

                    h2{
                        font-size: 1.8rem;
                        margin-top: 1.5rem;
                    }
                }

                .right{

                    .akcija{
                        width: 90%;
                        margin-bottom: 1.5rem;
                        padding: 1.5rem;

                        i{
                            font-size: 2rem;
                        }

                        h3{
                            font-size: 1.6rem;
                        }

                        .akcija-item-info{

                            p{
                                font-size: 1.05rem;
                            }
                        }
                        
                    }

                    img{
                        width: 90%;
                        height: 50%;
                    }
                }
            }        

            .landing-items{
                padding: 0 2.5rem 2rem 2.5rem;

                .item{
                    margin-top: 2rem;
                    padding: 3rem 1.5rem;

                    .hr{
                        width: 30%;
                    }
                    
                    i{
                        font-size: 1.8rem;
                    }

                    h2{
                        font-size: 1.4rem;
                    }

                    p{
                        font-size: 1.05rem;
                    }
                }
            }
        }

    }

    
    .laptop{
        main{
            .landing{

                .left{
                    padding: 1.5rem;

                    img{
                        height: 8rem;
                    }

                    h2{
                        font-size: 1.5rem;
                        margin-top: 1.3rem;
                    }
                }

                .right{
                    padding: 1.5rem;

                    .akcija{
                        width: 90%;
                        margin-bottom: 1.3rem;
                        padding: 1.3rem;

                        i{
                            font-size: 1.8rem;
                        }

                        h3{
                            font-size: 1.3rem;
                        }

                        .akcija-item-info{

                            p{
                                font-size: 1rem;
                            }
                        }
                        
                    }

                    img{
                        width: 90%;
                        height: 50%;
                    }
                }
            }        

            .landing-items{
                padding: 0 2rem 1.5rem 2rem;

                .item{
                    width: 32%;
                    margin-top: 1.5rem;
                    padding: 2rem 1.3rem;

                    .hr{
                        width: 40%;
                    }
                    
                    i{
                        font-size: 1.5rem;
                    }

                    h2{
                        font-size: 1.3rem;
                    }

                    p{
                        font-size: 1rem;
                    }
                }
            }
        }

    }

    
    .tablet{
        main{
            .landing{
                flex-direction: column;
                min-height: auto;
                margin-top: 60px; 

                .left{
                    width: 100%;
                    padding: 2rem;

                    img{
                        height: 7rem;
                    }

                    h2{
                        font-size: 1.3rem;
                        margin-top: 1.3rem;
                    }
                }

                .right{
                    flex-direction: column-reverse;
                    width: 100%;
                    padding: 2rem;
                    padding-top: 0;

                    .akcija{
                        width: 100%;
                        margin-bottom: 0;
                        margin-top: 1.5rem;
                        padding: 1.3rem;

                        i{
                            font-size: 1.5rem;
                        }

                        h3{
                            font-size: 1.2rem;
                        }

                        .akcija-item-info{

                            p{
                                font-size: 1rem;
                            }
                        }
                        
                    }

                    img{
                        width: 100%;
                        height: 50%;
                    }
                }
            }        

            .landing-items{
                flex-direction: column;
                padding: 0 2rem 1.5rem 2rem;

                .item{
                    width: 100%;
                    margin-top: 1.5rem;
                    padding: 2rem 1.3rem;

                    .hr{
                        width: 35%;
                    }
                    
                    i{
                        font-size: 1.3rem;
                    }

                    h2{
                        font-size: 1.2rem;
                    }

                    p{
                        font-size: 1rem;
                    }
                }
            }
        }

    }

    
    .phone{
        main{
            .landing{
                flex-direction: column;
                min-height: auto;
                margin-top: 60px; 

                .left{
                    width: 100%;
                    padding: 1rem;

                    img{
                        height: 5rem;
                    }

                    h2{
                        font-size: 1.1rem;
                        width: 95%;
                        margin-top: 1rem;
                    }
                }

                .right{
                    flex-direction: column-reverse;
                    width: 100%;
                    padding: 1rem;
                    padding-top: 0;

                    .akcija{
                        width: 100%;
                        margin-bottom: 0;
                        margin-top: 1.3rem;
                        padding: 1rem;

                        i{
                            font-size: 1.3rem;
                        }

                        h3{
                            font-size: 1.1rem;
                        }

                        .akcija-item-info{

                            p{
                                font-size: 0.9rem;
                            }
                        }
                        
                    }

                    img{
                        width: 100%;
                        height: 50%;
                    }
                }
            }        

            .landing-items{
                flex-direction: column;
                padding: 0 1em 1rem 1em;

                .item{
                    width: 100%;
                    margin-top: 1.3rem;
                    padding: 1.3rem 1rem;

                    .hr{
                        width: 35%;
                    }
                    
                    i{
                        font-size: 1.2rem;
                    }

                    h2{
                        font-size: 1.1rem;
                    }

                    p{
                        font-size: 0.9rem;
                    }
                }
            }
        }

    }

    
    .phoneS{
        main{
            .landing{
                flex-direction: column;
                min-height: auto;
                margin-top: 60px; 

                .left{
                    width: 100%;
                    padding: 1rem;

                    img{
                        height: 5rem;
                    }

                    h2{
                        font-size: 1rem;
                        width: 95%;
                        margin-top: 1rem;
                    }
                }

                .right{
                    flex-direction: column-reverse;
                    width: 100%;
                    padding: 1rem;
                    padding-top: 0;

                    .akcija{
                        width: 100%;
                        margin-bottom: 0;
                        margin-top: 1rem;
                        padding: 1rem;

                        i{
                            font-size: 1.1rem;
                        }

                        h3{
                            font-size: 1rem;
                        }

                        .akcija-item-info{

                            p{
                                font-size: 0.8rem;
                            }
                        }
                        
                    }

                    img{
                        width: 100%;
                        height: 50%;
                    }
                }
            }        

            .landing-items{
                flex-direction: column;
                padding: 0 1rem 1rem 1rem;

                .item{
                    width: 100%;
                    margin-top: 1.1rem;
                    padding: 1rem;

                    .hr{
                        width: 35%;
                    }
                    
                    i{
                        font-size: 1.1rem;
                    }

                    h2{
                        font-size: 1rem;
                    }

                    p{
                        font-size: 0.8rem;
                    }
                }
            }
        }

    }
</style>