<template>
    <ul class="serije-navbar" :class="$mq">


        <div v-if="isLargeScreen" class="flex-row">

            <li v-for="serija in serije" :key="serija.id" class="noselect">
                <a @click="scroll(serija.route_param)">{{serija.title}}</a>
            </li>

        </div>


        <div  @click="menuUpDown()" v-if="!isLargeScreen" class="navbar-mobile flex-column"> 

            <li class="dropdown-top">Idi na <i class="fas fa-arrow-down"></i></li>
            
            <div class="dropdown-navbar" v-if="show_dropdown">
                <li v-for="serija in serije" :key="serija.id" class="noselect">
                    <a @click="scroll(serija.route_param)">{{serija.title}}</a>
                </li>
            </div>

        </div>


    </ul>
</template>

<script>
    export default {
        name: 'serije-navbar',

        data(){
            return{
                serije: [],
                show_dropdown: false
            }
        },

        computed: {
            isLargeScreen(){
                if(this.$mq === 'desktop' || this.$mq === 'laptopL')
                    return true

                return false
            }
        },

        mounted(){
            this.fetch("series").then((response) => {
                this.serije = response.data.data;
            })
        },

        methods: {
        
            scroll(hash){
                let offset = -85;
                if(window.pageYOffset == 0){
                    offset = -180;
                }

                let pos = document.getElementById(hash);

                this.$smoothScroll({
                    scrollTo: pos,
                    offset: offset
                });

                
            },

            menuUpDown(){
                this.show_dropdown = !this.show_dropdown
            }
        }
    }
</script>

<style lang="scss" scoped>
    ul{
        justify-content: space-evenly;
        background-color: $light-2;
        width: 100%;
        height: 3.5rem;

        .flex-row{
            height: 3.5rem;
            justify-content: space-evenly;
            border: 1px solid $light-3;
            border-top-width: 0;
        }

        li{
            a{
                cursor: pointer;
                color: $dark-2;
                font-size: 0.9rem;
                font-weight: 600;
                text-transform: uppercase;

                &:hover{
                    color: $primary-orange;
                }
            }
        }       
    }


    //**********MOBILE NAVBAR************//
    .navbar-mobile{
        width: 50%;
        margin: 0 auto;
        text-align: center;

        li{
            cursor: pointer;
            color: $dark-2;
            font-size: 1.1rem;
            font-weight: 600;
            padding: 0.4rem;

            a{
                font-size: 0.9rem;
            }
        }

        li:hover{
            color: $primary-orange;
        }

        li i{
            font-size: 0.9rem;
            color: $primary-orange;
        }

        .dropdown-top{
            line-height: 3.5rem;
            padding: 0;
        }

        .dropdown-navbar{
            z-index: 900;
            position: sticky;
            top: 3rem;
            background-color: $light-2;
            width: 50%;
            border: 1px solid $light-3;
            border-top-width: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }


    //**********RESPONSIVE************//


    .laptopL{

        li{
            font-size: 1rem;

            i{
                font-size: 0.8rem;
            }

            a{
                font-size: 0.8rem;
            }
        }
    }  

    .laptop{

        .dropdown-navbar{
            li{                
                padding: 0.3rem;
            }
        }

        li{
            font-size: 0.95rem;

            i{
                font-size: 0.75rem;
            }

            a{
                font-size: 0.75rem;
            }
        }
    }  

    .tablet{
        margin-top: 60px;

        .navbar-mobile{    
            width: 70%;            
            margin:  0 auto;
        }

        li{
            font-size: 0.95rem;

            i{
                font-size: 0.75rem;
            }

            a{
                font-size: 0.75rem;
            }
        }
    }

    .phone{
        height: 3rem;
        margin-top: 60px;

        .navbar-mobile{    
            width: 90%;             
            margin: 0 auto;

            li{
                font-size: 0.9rem;

                i{
                    font-size: 0.7rem;
                }

                a{
                    font-size: 0.75rem;
                }
            }

            .dropdown-top{
                line-height: 3rem;
            }

            .dropdown-navbar{
                font-size: 1rem;
            }
        }
    }

    .phoneS{
        height: 3rem;
        margin-top: 60px;

        .navbar-mobile{      
            width: 100%;           
            margin: 0 auto;

            li{
                font-size: 0.85rem;

                i{
                    font-size: 0.65rem;
                }

                a{
                    font-size: 0.7rem;
                }
            }

            .dropdown-top{
                line-height: 3rem;
            }

            .dropdown-navbar{
                top: calc(3rem + 60px);
            }
        }
    }
</style>