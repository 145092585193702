<template>
    <ul class="flex-column" :class="$mq">

        <router-link tag="li" v-for="serija in this.serije" :key="serija.id" :to="`/prodaja/${serija.route_param}/`">
            <a href="#">{{serija.title}}</a>
        </router-link>

    </ul>
</template>

<script>
    
    export default {
        name: 'expand-serije',

        data(){
            return{
                serije: []
            }
        },

        mounted(){
            this.fetch("series").then((response) => {
                this.serije = response.data.data;
            })      
        },
    }
</script>

<style lang="scss" scoped>
    ul{
        height: 100%;
        justify-content: space-around;
        align-items: flex-start;
    }

    a{
        text-transform: uppercase;
        color: $dark-4;
        font-size: 1rem;
        font-weight: 600;
    }

    ul li a:hover{
        color: $primary-orange!important;
    }

    //**********RESPONSIVE************//
    .laptopL{
        a{
            font-size: 0.9rem;
        }
    }

    .laptop{
        a{
            font-size: 0.8rem;
        }
    }
</style>