<template>
    <div class="tehnologija flex-column" :class="$mq">
        <div class="content flex-column">


            <div class="top flex-row">

                <h1>{{ this.tehnologija.title }}</h1>

                <div class="icon flex-column">                
                    <img :src="`${img()}`" :alt="`${this.tehnologija.title} grafički prikaz`">
                </div>

            </div>


            <div class="info flex-colum">

                <h3>Princip rada</h3>

                <p v-html="this.tehnologija.text"></p>

            </div>        


            <div class="serije flex-column">

                <h4>Odgovarajuće serije pumpi</h4>

                <div class="items flex-row">                    
                    <router-link tag="a" class="flex-row" v-for="(serija, index) in this.serije" :key="index" :to="`/prodaja/${serija.route_param}/`">
                        <i class="fas fa-plus"></i>
                        <span>{{serija.title}}</span>
                    </router-link>                    
                </div>

            </div>


        </div>
    </div>
</template>

<script>

    export default {
        name: 'tehnologija',
        metaInfo () {
            return {
                title: this.tehnologija.title,
                meta: [
                    {name: 'description', content: this.tehnologija.meta_description},
                ],
                link: [
                    {rel: 'canonical', href: 'https://www.vakumpumpe.com/princip_rada/'+this.tehnologija_route+'/'}
                ]
            }
        },

        props: [
            'tehnologija_route'
        ],

        data(){
            return{
                tehnologija: {},
                serije: []
            }
        },

        mounted(){
            this.fetch("technology", null, this.tehnologija_route).then((response) => {
                this.tehnologija = response.data.data;
            }).then(() => {
                this.fetch("series", "technology", this.tehnologija.id).then((response) => {
                    this.serije = response.data.data;
                })
            })
        },

        methods: {
            img(){
                return require("../assets/img/tehnologija/icon/"+this.tehnologija.icon)
            }
        },
    }
</script>

<style lang="scss" scoped>

    .tehnologija{
        justify-content: center;
    }

    .content{
        background-color: $light-2;
        width: 90%;
        height: auto;
        margin: 3rem auto;
        border-radius: 3px;

        .top{
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            
            h1{
                background-color: $primary-orange;
                color: $light-2;
                font-size: 2.7rem;
                padding: 1rem 3rem;
                border-radius: 0 3px 3px 0;
            }

            .icon{
                justify-content: center;
                background-color: $dark-1;
                width: 14rem;
                height: 14rem;
                border-radius: 0 3px 0 3px;

                img{
                    width: 50%;
                }
            }
        }

        .info{
            padding: 1.5rem 3rem 2rem 3rem;
            box-sizing: border-box;

            h3{
                color: $dark-2;
                font-size: 1.8rem;
            }

            p{
                color: $dark-3;
                font-size: 1.1rem;
                font-weight: 600;
            }
        }

        .serije{
            background-color: $dark-1;
            width: 100%;
            padding: 2rem 3rem;
            box-sizing: border-box;
            border-radius: 0 0 3px 3px;

            h4{
                text-align: center;
                color: $light-1;
                font-size: 1.8rem;
            }

            .items{                
                justify-content: center;
                align-items: flex-start;
                margin: 1rem;

                a{
                    cursor: pointer;
                    color: $light-2;
                    font-size: 1.4rem;
                    font-weight: 600;
                    margin: 0 1rem;
                    padding: 1.5rem;
                    box-sizing: border-box;
                    border-radius: 3px;
                }

                i{
                    font-size: 1.1rem;
                    color: $primary-orange;
                    margin-right: 0.5rem;
                }

                a:hover{
                    background-color: $dark-2;
                }

                img{
                    height: 5rem;
                    margin-top: 1rem;
                }
            }
        }
    }


    .laptopL{

        .content{
            width: 90%;

            .top{
                
                h1{
                    font-size: 2.5rem;
                    padding: 1rem 2.5rem;
                }

                .icon{
                    width: 13rem;
                    height: 13rem;
                }
            }

            .info{
                padding: 1.5rem 2.5rem 2rem 2.5rem;

                h3{
                    font-size: 1.5rem;
                }

                p{
                    font-size: 1rem;
                }
            }

            .serije{
                padding: 2rem 2.5rem;

                h4{
                    font-size: 1.5rem;
                }

                .items{                
                    margin: 1rem;

                    a{
                        font-size: 1.3rem;
                        margin: 0 1rem;
                        padding: 1.3rem;
                    }

                    i{
                        font-size: 1.05rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }


    .laptop{

        .content{
            width: 90%;

            .top{
                
                h1{
                    font-size: 2rem;
                    padding: 1rem 2rem;
                }

                .icon{
                    width: 11rem;
                    height: 11rem;
                    
                    img{
                        width: 45%;
                    }
                }
            }

            .info{
                padding: 1rem 2rem 1.5rem 2rem;

                h3{
                    font-size: 1.3rem;
                }

                p{
                    font-size: 1rem;
                }
            }

            .serije{
                padding: 1.5rem 2rem;

                h4{
                    font-size: 1.3rem;
                }

                .items{                
                    margin: 0.8rem;

                    a{
                        font-size: 1.2rem;
                        margin: 0 1rem;
                        padding: 1.3rem;
                    }

                    i{
                        font-size: 1rem;
                        margin-right: 0.5rem;
                    }
                }
            }
        }
    }


    .tablet{

        .content{
            width: 90%;
            margin-top: calc(60px + 3rem);

            .top{
                
                h1{
                    font-size: 1.8rem;
                    max-width: 45%;
                    padding: 0.8rem 2rem;
                }

                .icon{
                    width: 6rem;
                    height: 6rem;
                    margin-bottom: 5rem;
                    
                    img{
                        width: 50%;
                    }
                }
            }

            .info{
                padding: 1rem 2rem 1.5rem 2rem;

                h3{
                    font-size: 1.2rem;
                }

                p{
                    font-size: 0.95rem;
                }
            }

            .serije{
                padding: 1.5rem 2rem;

                h4{
                    font-size: 1.2rem;
                }

                .items{                
                    display: grid; display: -ms-grid;
                    grid-template-columns: 1fr 1fr;
                    margin: 0.5rem 0;

                    a{
                        align-items: center;
                        background-color: $dark-2;
                        font-size: 1.1rem;
                        padding: 1rem;
                        margin: 0.3rem;
                        border-radius: 2px;
                    }

                    i{
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }


    .phone{

        .content{
            width: 95%;
            margin-top: calc(60px + 3rem);

            .top{
                
                h1{
                    font-size: 1.5rem;
                    max-width: 55%;
                    padding: 0.5rem 1.2rem;
                }

                .icon{
                    width: 5rem;
                    height: 5rem;
                    margin-bottom: 4rem;
                    
                    img{
                        width: 50%;
                    }
                }
            }

            .info{
                padding: 0.8rem 1.2rem 1rem 1.2rem;

                h3{
                    font-size: 1.1rem;
                }

                p{
                    font-size: 0.9rem;
                }
            }

            .serije{
                padding: 1rem 1.2rem;

                h4{
                    font-size: 1.1rem;
                }

                .items{              
                    display: grid; display: -ms-grid;
                    grid-template-columns: 1fr 1fr;
                    margin: 0.5rem 0;

                    a{
                        align-items: center;
                        background-color: $dark-2;
                        font-size: 0.95rem;
                        padding: 1rem 0.8rem;
                        margin: 0.3rem;
                        border-radius: 2px;
                    }

                    i{
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }


    .phoneS{

        .content{
            width: 95%;
            margin-top: calc(60px + 3rem);

            .top{
                
                h1{
                    font-size: 1.3rem;
                    width: 55%;
                    padding: 0.5rem 1rem;
                }

                .icon{
                    width: 4rem;
                    height: 4rem;
                    margin-bottom: 3rem;
                    
                    img{
                        width: 50%;
                    }
                }
            }

            .info{
                padding: 0.8rem 1rem 1rem 1rem;

                h3{
                    font-size: 1rem;
                }

                p{
                    font-size: 0.8rem;
                }
            }

            .serije{
                padding: 1rem 1rem;

                h4{
                    font-size: 1rem;
                }

                .items{           
                    display: grid; display: -ms-grid;
                    grid-template-columns: 1fr;
                    margin: 0.5rem 0;

                    a{
                        align-items: center;
                        background-color: $dark-2;
                        font-size: 0.9rem;
                        padding: 1rem;
                        margin: 0.2rem;
                        border-radius: 2px;
                    }

                    i{
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

</style>