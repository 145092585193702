<template>
    <div class="tehnologija flex-column" :class="$mq">


        <h2>{{this.tehnologija.title}}</h2>


        <div class="content flex-row">

            <img :src="`${icon()}`" :alt="`${this.tehnologija.title} crtez`">

            <div class="info flex-column">
                <div class="items flex-column">                    
                    <router-link tag="a" class="flex-row" v-for="(serija, index) in this.serije" :key="index" :to="`/prodaja/${serija.route_param}/`">
                        <i class="fas fa-plus"></i>
                        <span>{{serija.title}}</span>
                    </router-link>                    
                </div>
            </div>

            <router-link tag="a" :to="`/princip-rada/${this.tehnologija.route_param}/`" class="btn btn-orange">Detalji</router-link>

        </div>


    </div>
</template>

<script>
    export default {
        name: 'tehnologija-home-small',

        props: [
            'tehnologija', 'index'
        ],

        data(){
            return{
                serije: []
            }
        },

        mounted(){
            this.fetch("series", "technology", this.tehnologija.id).then((response) => {
                this.serije = response.data.data;
            })
        },

        methods:{
            icon(){
                if(this.$mq == 'desktop' || this.$mq == 'laptopL' || this.$mq == 'laptop'){                    
                    if( (this.index + 1) % 4 == 1 || (this.index + 1) % 4 == 0)
                        return require("../../assets/img/tehnologija/icon/"+"dark-"+this.tehnologija.icon)
                    else
                        return require("../../assets/img/tehnologija/icon/"+this.tehnologija.icon)
                }else{                      
                    if( (this.index + 1) % 2 == 0)
                        return require("../../assets/img/tehnologija/icon/"+"dark-"+this.tehnologija.icon)
                    else
                        return require("../../assets/img/tehnologija/icon/"+this.tehnologija.icon)
                }
            },            

            img(i){
                return require("../../assets/img/pumpe/thumbnail/series/"+this.serije[i].img)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tehnologija{
        background-color: $dark-1;
        width: 100%;
        padding: 2rem 3rem;
        box-sizing: border-box;
        border-radius: 3px;

        h2{
            text-align: center;
            color: $light-1;
            font-size: 2rem;
            margin-bottom: 1rem;
        }

        .content{
            justify-content: space-between;
            width: 100%;

            img{
                width: 7rem;
            }

            .items{

                a{
                    text-transform: uppercase;
                    color: $light-2;
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding: 0.5rem;
                    transition: 0.3s ease-in-out;

                    i{
                        color: $primary-orange;
                        font-size: 1.1rem;
                        margin-right: 0.5rem;
                        transition: 0.3s ease-in-out;
                    }

                    &:hover{
                        background-color: #e95201;

                        i{
                            transform: scale(1.3);
                        }
                    }
                }
            }
            
        }

        &:nth-child(4n), &:nth-child(4n+1){
            background-color: $primary-orange;

            h2{
                color: $light-1;
            }

            .items{

                a{
                    color: $light-2;

                    i{
                        color: $dark-1
                    }
                }
            }            

            .btn{
                border-color: $dark-1;
                background-color: $dark-1;
                color: $light-1;

                &:hover{
                    border-color: $dark-2;
                    background-color: $dark-2;
                }
            }
        }

        
    }

    //**********RESPONSIVE************//
    .laptopL{
        padding: 2rem;

        h2{
            font-size: 1.8rem;
        }

        .content{

            img{
                width: 6rem;
            }

            .items{

                a{
                    font-size: 1.3rem;

                    i{
                        font-size: 1rem;
                        margin-right: 0.4rem;
                    }

                    &:hover{
                        i{
                            transform: scale(1.25);
                        }
                    }
                }
            }            
        }
    }
    
    .laptop{
        padding: 2rem;

        h2{
            font-size: 1.5rem;
        }

        .content{

            img{
                width: 5rem;
            }

            .items{

                a{
                    font-size: 1.2rem;

                    i{
                        font-size: 0.9rem;
                        margin-right: 0.4rem;
                    }

                    &:hover{
                        i{
                            transform: scale(1.25);
                        }
                    }
                }
            }            
        }
    }
    
    .tablet{
        padding: 2rem;

        h2{
            font-size: 1.3rem;
        }

        .content{

            img{
                width: 4rem;
            }

            .items{

                a{
                    font-size: 1.1rem;
                    margin-bottom: 0.2rem;

                    i{
                        font-size: 0.8rem;
                        margin-right: 0.3rem;
                    }

                    &:hover{
                        i{
                            transform: scale(1.25);
                        }
                    }
                }
            }      
        }

        &:nth-child(even){
            background-color: $primary-orange;

            h2{
                color: $light-1;
            }

            .items{

                a{
                    color: $light-2;

                    i{
                        color: $dark-1
                    }
                }
            }            

            .btn{
                border-color: $dark-1;
                background-color: $dark-1;
                color: $light-1;

                &:hover{
                    border-color: $dark-2;
                    background-color: $dark-2;
                }
            }
        }

        &:nth-child(odd){
            background-color: $dark-1;

            .items{

                a{

                    i{
                        color: $primary-orange
                    }
                }
            }            

            .btn{
                color: $primary-light;
                border-color: $primary-orange;
                background-color: $primary-orange;

                &:hover{
                    background-color: transparent;
                    color: $primary-orange;
                    border-color: $primary-orange;
                }
            }
        }
    }
    
    .phone{
        padding: 1.5rem;

        h2{
            font-size: 1.1rem;
        }

        .content{

            img{
                width: 3.5rem;
            }

            .items{

                a{
                    font-size: 0.95rem;
                    margin-bottom: 0.2rem;
                    padding: 0.3rem;

                    i{
                        font-size: 0.7rem;
                        margin-right: 0.3rem;
                    }

                    &:hover{
                        i{
                            transform: scale(1.25);
                        }
                    }
                }
            }      
        }

        &:nth-child(even){
            background-color: $primary-orange;

            h2{
                color: $light-1;
            }

            .items{

                a{
                    color: $light-2;

                    i{
                        color: $dark-1
                    }
                }
            }            

            .btn{
                border-color: $dark-1;
                background-color: $dark-1;
                color: $light-1;

                &:hover{
                    border-color: $dark-2;
                    background-color: $dark-2;
                }
            }
        }

        &:nth-child(odd){
            background-color: $dark-1;

            .items{

                a{

                    i{
                        color: $primary-orange
                    }
                }
            }            

            .btn{
                color: $primary-light;
                border-color: $primary-orange;
                background-color: $primary-orange;

                &:hover{
                    background-color: transparent;
                    color: $primary-orange;
                    border-color: $primary-orange;
                }
            }
        }
    }
    
    .phoneS{
        padding: 1rem;

        h2{
            font-size: 1rem;
        }

        .content{

            img{
                width: 3rem;
            }

            .items{

                a{
                    font-size: 0.8rem;
                    margin-bottom: 0.1rem;
                    padding: 0.3rem;

                    i{
                        font-size: 0.6rem;
                        margin-right: 0.2rem;
                    }

                    &:hover{
                        i{
                            transform: scale(1.25);
                        }
                    }
                }
            }      
        }

        &:nth-child(even){
            background-color: $primary-orange;

            h2{
                color: $light-1;
            }

            .items{

                a{
                    color: $light-2;

                    i{
                        color: $dark-1
                    }
                }
            }            

            .btn{
                border-color: $dark-1;
                background-color: $dark-1;
                color: $light-1;

                &:hover{
                    border-color: $dark-2;
                    background-color: $dark-2;
                }
            }
        }

        &:nth-child(odd){
            background-color: $dark-1;

            .items{

                a{

                    i{
                        color: $primary-orange
                    }
                }
            }            

            .btn{
                color: $primary-light;
                border-color: $primary-orange;
                background-color: $primary-orange;

                &:hover{
                    background-color: transparent;
                    color: $primary-orange;
                    border-color: $primary-orange;
                }
            }
        }
    }


    // .laptopL{
    
    //     header{
    //         img{
    //             width: 2.7rem;
    //             height: 2.7rem;
    //         }

    //         h2{
    //             font-size: 1.8rem;
    //         }
    //     }
        
    //     main{

    //         .info{
    //             flex-basis: 75%;

    //             h3{
    //                 font-size: 1.4rem;
    //             }

    //             p{
    //                 font-size: 1rem;
    //             }
    //         }
            
    //         .serije{

    //             h4{
    //                 font-size: 1.4rem;
    //             }

    //             ul{
    //                 font-size: 1.1rem;
    //                 margin-top: 0;
    //             }
                
    //         }

    //     }
    // }

    // .laptop{
    //     padding: 3rem 7%;
    
    //     header{
    //         img{
    //             width: 2.5rem;
    //             height: 2.5rem;
    //         }

    //         h2{
    //             font-size: 1.5rem;
    //         }
    //     }
        
    //     main{

    //         .info{
    //             flex-basis: 75%;

    //             h3{
    //                 font-size: 1.3rem;
    //             }

    //             p{
    //                 font-size: 0.9rem;
    //             }
    //         }
            
    //         .serije{

    //             h4{
    //                 font-size: 1.3rem;
    //             }

    //             ul{
    //                 font-size: 1rem;
    //                 margin-top: 0;
    //             }
                
    //         }

    //     }
    // }

    // .tablet{
    //     position: relative;
    //     padding: 2rem 1.5rem;
    //     overflow: hidden;
    
    //     header{
             
    //         img{
    //             position: absolute;
    //             top: 50%;
    //             left: 0;
    //             transform: translateY(-50%);
    //             z-index: 1;
    //             opacity: 0.07;
    //             width: 80%;
    //             height: 80%;
    //             margin-left: 10%;
    //         }

    //         h2{
    //             z-index: 2;
    //             font-size: 1.4rem;
    //             margin-left: 0;
    //         }
    //     }
        
    //     main{
    //         flex-direction: column;
    //         align-items: flex-start;

    //         .info{
    //             z-index: 2;

    //             h3{
    //                 font-size: 1.2rem;
    //                 margin-left: 0;
    //             }

    //             p{
    //                 font-size: 0.8rem;
    //                 margin-left: 0;
    //                 margin-top: 0.3rem;
    //             }
    //         }
            
    //         .serije{
    //             flex-basis: 100%;
    //             align-items: flex-start;
    //             margin-top: 1rem;

    //             h4{
    //                 font-size: 1.2rem;
    //             }

    //             ul{
    //                 flex-direction: row;
    //                 font-size: 0.95rem;
    //                 margin-top: 0;

    //                 li{

    //                     margin-right: 2rem;
    //                 }
    //             }
                
    //         }

    //     }
    // }

    // .phone{
    //     position: relative;
    //     padding: 2rem 1rem;
    //     overflow: hidden;
    
    //     header{
             
    //         img{
    //             position: absolute;
    //             top: 50%;
    //             left: 0;
    //             transform: translateY(-50%);
    //             z-index: 1;
    //             opacity: 0.09;
    //             width: 80%;
    //             height: 80%;
    //             margin-left: 10%;
    //         }

    //         h2{
    //             z-index: 2;
    //             font-size: 1.2rem;
    //             margin-left: 0;
    //         }
    //     }
        
    //     main{
    //         flex-direction: column;
    //         align-items: flex-start;

    //         .info{
    //             z-index: 2;

    //             h3{
    //                 font-size: 1.1rem;
    //                 margin-left: 0;
    //             }

    //             p{
    //                 font-size: 0.8rem;
    //                 margin-left: 0;
    //                 margin-top: 0.3rem;
    //             }
    //         }
            
    //         .serije{
    //             flex-basis: 100%;
    //             align-items: flex-start;
    //             margin-top: 1rem;

    //             h4{
    //                 font-size: 1.2rem;
    //             }

    //             ul{
    //                 flex-direction: row;
    //                 font-size: 0.9rem;
    //                 margin-top: 0;

    //                 li{

    //                     margin-right: 2rem;
    //                 }
    //             }
                
    //         }

    //     }
    // }

    // .phoneS{
    //     position: relative;
    //     padding: 1rem;
    //     overflow: hidden;
    
    //     header{
             
    //         img{
    //             position: absolute;
    //             top: 50%;
    //             left: 0;
    //             transform: translateY(-50%);
    //             z-index: 1;
    //             opacity: 0.09;
    //             width: 80%;
    //             height: 80%;
    //             margin-left: 10%;
    //         }

    //         h2{
    //             z-index: 2;
    //             font-size: 1rem;
    //             margin-left: 0;
    //         }
    //     }
        
    //     main{
    //         flex-direction: column;
    //         align-items: flex-start;

    //         .info{
    //             z-index: 2;

    //             h3{
    //                 font-size: 0.9rem;
    //                 margin-left: 0;
    //                 margin-top: 0.5rem;
    //             }

    //             p{
    //                 font-size: 0.75rem;
    //                 margin-left: 0;
    //                 margin-top: 0.3rem;
    //             }
    //         }
            
    //         .serije{
    //             flex-basis: 100%;
    //             align-items: flex-start;
    //             margin-top: 0.5rem;

    //             h4{
    //                 font-size: 0.9rem;
    //             }

    //             ul{
    //                 flex-direction: row;
    //                 font-size: 0.8rem;
    //                 margin-top: 0;

    //                 li{

    //                     margin-right: 2rem;
    //                 }
    //             }
                
    //         }

    //     }
    // }
</style>