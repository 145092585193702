<template>
    <main class="flex-column" :class="$mq">



        <div class="about grid-2">
            <div class="content">
                <h2>
                    <i :class="`${this.aplikacija.icon}`"></i>
                    {{this.aplikacija.title}}
                </h2>

                <p v-html="this.aplikacija.text"></p>
            </div>


            <div class="img" >
                <div class="contact">
                    <h3>Dodatne informacije:</h3>
                    <p><i class="fas fa-mobile-alt"></i><a href="tel:+38163587439">+381 (063) 587-439</a></p>
                    <p><i class="far fa-envelope"></i><a href="mailto:univerzalkomerc@gmail.com">univerzalkomerc@gmail.com</a></p>
                    <router-link tag="a" to="/kontakt/">Kontakt forma</router-link>
                </div>
            </div>
        </div>
        


        <div class="serije flex-column">
            <h3>Odgovarajuće serije pumpi</h3>


            <div class="list flex-row">
                <router-link tag="a" :to="`/prodaja/${serija.route_param}/`" class="flex-column" v-for="(serija, index) in this.serije" :key="index"> 
                    <p><i class="fas fa-plus"></i>{{serija.title}}  </p>      
                    <!-- <img :src="`${img(index)}`" :alt="`${serija.title}`"> -->
                </router-link>
            </div>
        </div>

        

    </main>
</template>

<script>
    export default {
        name: 'aplikacija',
        metaInfo () {
            return {
                title: this.title,
                meta: [
                    {name: 'robots', content: 'noindex, nofollow'}
                ],
                link: [
                    {rel: 'canonical', href: 'https://www.vakumpumpe.com/aplikacija/'+this.aplikacija_route+'/'}
                ]
            }
        },

        props: [
            'aplikacija_route'
        ],

        data(){
            return{
                aplikacija: {},
                serije: []
            }
        },

        computed: {
            background(){
                return require("../assets/img/aplikacije/bg/"+this.aplikacija.img)
            },

            title(){
                switch(this.aplikacija_route){
                    case 'prehrambenaindustrija':
                        return 'Prehrambena industrija'
                    case 'industrijapica':
                        return 'Industrija pića'
                    case 'pakovanje':
                        return 'Pakovanje'
                    case 'mlekarskaindustrija':
                        return 'Mlekarska industrija'
                    case 'hemija':
                        return 'Hemija'
                    case 'drvnaindustrija':
                        return 'Drvna industrija'
                    case 'medicina':
                        return 'Medicina'
                    case 'obnovljivaenergija':
                        return 'Obnovljiva Energija'
                    case 'keramika':
                        return 'Keramika'
                    case 'papirnaindustrija':
                        return 'Papirna industrija'
                    case 'industrijaplastike':
                        return 'Industrija plastike'
                    case 'transport':
                        return 'Transport'
                    case 'industrijaautomobila':
                        return 'Industrija automobila'
                    case 'metalurgija':
                        return 'Metalurgija'
                    case 'elektronika':
                        return 'Elektronika'
                    case 'termoformiranje':
                        return 'Termoformiranje'            
                    default:
                        return ''        
                }
            }
        },

        mounted(){
            this.fetch("application", null, this.aplikacija_route).then((response) => {
                this.aplikacija = response.data.data;
                this.getBg()
            }).then(() => {
                this.fetch("series", "application", this.aplikacija.id).then((response) => {
                    this.serije = response.data.data;
                })
            })
        },

        methods: {
            img(i){
                return require("../assets/img/pumpe/large/"+this.serije[i].img)
            },

            getBg(){
                document.querySelector('.img').style.backgroundImage = `url(${this.background})`;
            }

        }
    }
</script>

<style lang="scss" scoped>

    main{
        justify-content: space-between;
    }

    .about{        
        background-color: $light-3;     

        .content{
            width: 90%;
            padding: 10rem 2rem;

            h2{
                font-size: 2.7rem;
            }

            i{
                color: $primary-orange;
            }

            p{
                color: $dark-3;
                font-weight: 600;
                font-size: 1.1rem;
                margin-top: 1.5rem;
            }
        }


        .img{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            width: 100%;
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 0 0 0 3px;
        }

        .contact{
            background-color: $dark-1;
            margin: 0 0 2rem 2rem;
            padding: 1.5rem 2rem;
            box-sizing: border-box;
            border-radius: 5px;

            h3{
                color: $light-1;
                font-size: 1.3rem;
                font-weight: 600;
                padding-bottom: 0.5rem;
                margin-bottom: 1rem;
                border-bottom: 2px solid $dark-4;
            }

            p{
                font-size: 1.1rem;
                font-weight: 500;
                color: $light-2;
                margin-bottom: 1.5rem;
                i{
                    color: $primary-orange;
                    margin-right: 0.8rem
                }
                a{border-bottom: hidden;}
            }

            a{
                color: $light-3;
                font-size: 1.1rem;
                font-weight: 500;
                border-bottom: 2px solid $primary-orange;
            }
        }
    }

    .serije{      
        background-color: $light-2;        
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;

        h3{
            text-align: center;
            color: $dark-1;
            font-size: 1.8rem;
        }

        .list{
            justify-content: space-between;
            align-items: flex-start;
            margin: 1rem;

            a{
                text-transform: uppercase;
                cursor: pointer;
                color: $dark-2;
                font-size: 1.4rem;
                font-weight: 600;
                margin: 0 1rem;
                padding: 1.5rem;
                box-sizing: border-box;
                border-radius: 3px;
                transition: 0.3s ease-in-out;
            }

            i{
                font-size: 1.3rem;
                color: $primary-orange;
                margin-right: 0.5rem;
                transition: 0.3s ease-in-out;
            }

            a:hover{
                background-color: $light-3;

                i{
                    transform: scale(1.2);
                }
            }

            img{
                height: 5rem;
                margin-top: 1rem;
            }
        }
    }


    //**********RESPONSIVE************//
    .laptopL{
        .content{
            padding: 7rem 2rem;

            h2{
                font-size: 2.5rem;
            }

            p{
                font-size: 1.1rem;
                margin-top: 1.3rem ;
            }
        }

        .contact{
            margin: 0 0 2rem 2rem;
            padding: 1.5rem 2rem;

            h3{
                font-size: 1.2rem;
            }

            p{
                font-size: 1rem;
                margin-bottom: 1.2rem;
            }

            a{
                font-size: 1rem;
            }
        }

        .serije{      
            padding: 2rem;

            h3{
                font-size: 1.5rem;
            }

            .list{

                a{
                    font-size: 1.3rem;
                    padding: 1.3rem;
                }

                i{
                    font-size: 1.1rem;
                }

                img{
                    height: 4.5rem;
                }
            }
        }
    }

    .laptop{
        .content{
            padding: 6rem 2rem;

            h2{
                font-size: 2.3rem;
            }

            p{
                font-size: 1rem;
                margin-top: 1.2rem ;
            }
        }

        .contact{
            margin: 0 0 1.5rem 1.5rem;
            padding: 1rem 1.5rem;

            h3{
                font-size: 1.1rem;
            }

            p{
                font-size: 0.9rem;
                margin-bottom: 1.2rem;
            }

            a{
                font-size: 0.9rem;
            }
        }

        .serije{      
            padding: 1.5rem;

            h3{
                font-size: 1.3rem;
            }

            .list{

                a{
                    font-size: 1.2rem;
                    padding: 1.3rem;
                }

                i{
                    font-size: 1rem;
                }

                img{
                    height: 4rem;
                }
            }
        }
    }

    .tablet{
        margin-top: 60px;

        .about{            
            grid-template-columns: 1fr;

            .content{
                width: 100%;
                padding: 2rem 1.5rem;
                box-sizing: border-box;

                h2{
                    font-size: 2rem;
                }

                p{
                    font-size: 1rem;
                    margin-top: 1rem;
                }
            }

            .img{
                padding: 3rem 0;
                box-sizing: border-box;
            }
        }

        .img{
            position: relative;
            height: 15rem;
        }

        .contact{
            position: absolute;
            bottom: 0;
            transform: translateY(7rem);
            align-self: flex-end;
            background-color: #222020e5;
            width: 100%;
            margin: 0;
            padding: 1rem 1.5rem;
            border-radius: 0;

            h3{
                font-size: 1.1rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.9rem;
                margin-bottom: 0.7rem;
                 i{margin-right: 0.5rem}
            }

            a{
                font-size: 0.9rem;
            }
        }

        .serije{      
            padding: 1.5rem;
            margin-top: 7rem;

            h3{
                font-size: 1.2rem;
            }

            .list{
                display: grid; display: -ms-grid;
                grid-template-columns: 1fr 1fr;
                margin: 0.5rem 0;

                a{
                    align-items: center;
                    background-color: $light-3;
                    font-size: 1.1rem;
                    padding: 1rem;
                    margin: 0.3rem;
                    border-radius: 2px;
                }

                i{
                    font-size: 0.9rem;
                }

                img{
                    height: 3rem;
                }
            }
        }
    }

    .phone{
        margin-top: 60px;

        .about{            
            grid-template-columns: 1fr;

            .content{
                width: 100%;
                padding: 1.5rem 1rem;
                box-sizing: border-box;

                h2{
                    font-size: 1.8rem;
                }

                p{
                    font-size: 0.9rem;
                    margin-top: 1rem;
                }
            }

            .img{
                padding: 2.5rem 0;
                box-sizing: border-box;
            }
        }

        .img{
            position: relative;
            height: 12rem;
        }


        .contact{
            position: absolute;
            bottom: 0;
            transform: translateY(6rem);
            align-self: center;
            background-color: #222020e5;
            width: 100%;
            margin: 0;
            padding: 1rem;
            border-radius: 0;

            h3{
                font-size: 1rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
                 i{margin-right: 0.5rem}
            }

            a{
                font-size: 0.8rem;
            }
        }

        .serije{      
            padding: 1rem;
            margin-top: 6rem;

            h3{
                font-size: 1.1rem;
            }

            .list{
                display: grid; display: -ms-grid;
                grid-template-columns: 1fr 1fr;
                margin: 0.5rem 0;

                a{
                    align-items: center;
                    background-color: $light-3;
                    font-size: 0.95rem;
                    padding: 1rem 0.8rem;
                    margin: 0.3rem;
                    border-radius: 2px;
                }

                i{
                    font-size: 0.9rem;
                }

                img{
                    height: 2.5rem;
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .phoneS{
        margin-top: 60px;

        .about{            
            grid-template-columns: 1fr;

            .content{
                width: 100%;
                padding: 1.5rem 1rem;
                box-sizing: border-box;

                h2{
                    font-size: 1.5rem;
                }

                p{
                    font-size: 0.8rem;
                    margin-top: 1rem;
                }
            }

            .img{
                padding: 1rem 0;
                box-sizing: border-box;
            }
        }

        .img{
            position: relative;
            height: 11rem;
        }

        .contact{
            position: absolute;
            bottom: 0;
            transform: translateY(6rem);
            align-self: center;
            background-color: #222020e5;
            width: 100%;
            margin: 0;
            padding: 1rem;
            border-radius: 0;

            h3{
                font-size: 0.9rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.75rem;
                margin-bottom: 0.5rem;
                 i{margin-right: 0.5rem}
            }

            a{
                font-size: 0.75rem;
            }
        }

        .serije{      
            padding: 1rem;
            margin-top: 6rem;

            h3{
                align-self: center;
                font-size: 1rem;
            }

            .list{
                display: grid; display: -ms-grid;
                grid-template-columns: 1fr;
                margin: 0.5rem 0;

                a{
                    align-items: center;
                    background-color: $light-3;
                    font-size: 0.9rem;
                    padding: 1rem;
                    margin: 0.2rem;
                    border-radius: 2px;
                }

                i{
                    font-size: 0.8rem;
                }

                img{
                    height: 2rem;
                    margin-top: 0.7rem;
                }
            }
        }
    }
</style>