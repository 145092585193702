<template>
    <div class="dropdown" :class="$mq" @mouseleave="select(0)">

        <ul class="flex-column">

            <router-link tag="li" class="flex-column" to="/princip-rada/">
                <a href="#" class="expand-option"  @mouseover="select(1)">Princip rada</a>
            </router-link>

            <router-link tag="li" class="flex-column" to="/prodaja/">
                <a href="#" class="expand-option"  @mouseover="select(2)">Prodaja</a>
            </router-link>

            <router-link tag="li" class="flex-column" to="/aplikacije/">
                <a href="#" class="expand-option"  @mouseover="select(3)">Aplikacije</a>
            </router-link>

            <router-link tag="li" class="flex-column" to="/servis/">
                <a href="#" class="expand-option" @mouseover="select(4)">Servis</a>
            </router-link>

            <router-link tag="li" class="flex-column" to="/rezervnidelovi/">
                <a href="#" class="expand-option" @mouseover="select(5)">Rezervni delovi</a>
            </router-link>

        </ul>


        <div class="expand">
            <component :is="componentName"></component>
        </div>
        

        <div class="busch-logo">
            <img src="../../assets/img/logo/logo-busch.png" alt="Busch logo">
        </div>        
        
    </div>
</template>

<script>
import ExpandTehnologije from './ExpandTehnologijeComponent.vue'
import ExpandSerije from './ExpandSerijeComponent.vue'
import ExpandAplikacije from './ExpandAplikacijeComponent.vue'
import ExpandServis from './ExpandServisComponent.vue'
import ExpandDelovi from './ExpandDeloviComponent.vue'


export default{
    name: 'dropdownPumpe',

    components: {
        'expand-tehnologije': ExpandTehnologije,
        'expand-serije': ExpandSerije,
        'expand-aplikacije': ExpandAplikacije,
        'expand-servis': ExpandServis,
        'expand-delovi': ExpandDelovi
    },

    data(){
        return{
            expand_key: 0
        }
    },

    computed: {
        componentName(){
            switch(this.expand_key){
                case 1:
                    return "expand-tehnologije"
                case 2:
                    return "expand-serije"
                case 3:
                    return "expand-aplikacije"
                case 4:
                    return "expand-servis"
                case 5:
                    return "expand-delovi"
                default:
                    return ""
            }
        }
    },

    methods: {
        set_key(key){
            this.expand_key = key;
        },

        select(key){
            const expand_options = document.getElementsByClassName('expand-option')

            switch(key){
                case 1:
                    this.set_key(1)
                    expand_options[0].classList.add('expand-selected')
                    expand_options[1].classList.remove('expand-selected')
                    expand_options[2].classList.remove('expand-selected')
                    expand_options[3].classList.remove('expand-selected')
                    expand_options[4].classList.remove('expand-selected')
                    break
                case 2:
                    this.set_key(2)
                    expand_options[1].classList.add('expand-selected')
                    expand_options[0].classList.remove('expand-selected')
                    expand_options[2].classList.remove('expand-selected')
                    expand_options[3].classList.remove('expand-selected')
                    expand_options[4].classList.remove('expand-selected')
                    break
                case 3:
                    this.set_key(3)
                    expand_options[2].classList.add('expand-selected')
                    expand_options[0].classList.remove('expand-selected')
                    expand_options[1].classList.remove('expand-selected')
                    expand_options[3].classList.remove('expand-selected')
                    expand_options[4].classList.remove('expand-selected')
                    break
                case 4:
                    this.set_key(4)
                    expand_options[3].classList.add('expand-selected')
                    expand_options[0].classList.remove('expand-selected')
                    expand_options[1].classList.remove('expand-selected')
                    expand_options[2].classList.remove('expand-selected')
                    expand_options[4].classList.remove('expand-selected')
                    break
                case 5:
                    this.set_key(5)
                    expand_options[4].classList.add('expand-selected')
                    expand_options[0].classList.remove('expand-selected')
                    expand_options[1].classList.remove('expand-selected')
                    expand_options[2].classList.remove('expand-selected')
                    expand_options[3].classList.remove('expand-selected')
                    break    
                default:
                    this.set_key(0)
                    expand_options[0].classList.remove('expand-selected')
                    expand_options[1].classList.remove('expand-selected')
                    expand_options[2].classList.remove('expand-selected')
                    expand_options[3].classList.remove('expand-selected')
                    expand_options[4].classList.remove('expand-selected')
            }
        }
    }    
}
</script>

<style lang="scss" scoped>
    .dropdown{
        z-index: 1000;
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
        background-color: $light-2;
        position: absolute;
        top: 120px;
        height: 65vh;
        width: 65%;
        padding: 1rem 2rem;
        box-sizing: border-box;
        transition: 0.4s ease-in-out;
        border: 1px $light-3 solid;
        border-top-width: 0;

        ul{
            justify-content: space-around;
            align-items: flex-start;
            line-height: 1rem;

            li{
                width: 100%;
                align-items: flex-start;

                a{
                    width: 70%;
                    color: $dark-3;
                    font-size: 1.05rem;
                    font-weight: 600;
                    padding: 10px;
                    transition: 300ms ease-in-out;
                    border-top-right-radius: 100px;
                    border-bottom-right-radius: 100px;
                }
            }
        }

        .expand-selected{
            color: $primary-light;
            background-color: $primary-orange;
        }

        .expand{
            min-height: 1px;
        }

        .busch-logo{
            display: flex;
            align-self: center;

            img{
                width: 80%;
                margin-left: auto;
            }
        }
    }

    //**********RESPONSIVE************//
    .laptopL{
        &.dropdown{
            width: 60%;

            ul li a{
                font-size: 0.95rem;
            }
        }
    }

    .laptop{
        &.dropdown{
            top: 110px;
            width: 65%;
        }

        ul li a{
            font-size: 0.85rem;
        }

        .busch-logo{

            img{
                width: 75%;
            }
        }
    }
    
</style>