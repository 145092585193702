<template>


    <main class="bg-light" :class="$mq">

        <tehnologije-navbar class="tehnologije-navbar"></tehnologije-navbar>

        <h1>Princip rada vakuum pumpi</h1>

        <div class="tehnologije flex-column">            
            <tehnologija-small v-for="(tehnologija, index) in tehnologije" :key="tehnologija.id" :tehnologija="tehnologija" :id="`${tehnologija.route_param}`" :index="index">   </tehnologija-small>
        </div>

    </main>


</template>

<script>
    import TehnologijaSmall from '@/components/tehnologija/TehnologijaSmallComponent.vue';
    import TehnologijeNavbar from '@/components/tehnologija/TehnologijeNavbarComponent.vue';

    export default {
        name: 'tehnologije',
        metaInfo: {
            title: 'Princip rada vakuum pumpi',
            meta: [
                {name: 'description', content: 'Detaljan opis rada, različitih tipova Busch vakuum pumpi. '}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.vakumpumpe.com/princip-rada/'}
            ]
        },

        components: {
            'tehnologija-small': TehnologijaSmall,
            'tehnologije-navbar': TehnologijeNavbar
        },

        data(){
            return{
                tehnologije: []
            }
        },

        computed: {
            isLargeScreen(){
                if(this.$mq === 'desktop' || this.$mq === 'laptopL')
                    return true

                return false
            }
        },

        mounted(){ 
            this.init();
        },

        methods: {
            init(){
                this.fetch('technologies').then((response) => {
                    this.tehnologije = response.data.data;
                }).then(() => {
                    this.scroll();
                }).catch((error) => {console.log(error)})
            },

            scroll(){
                let hash = this.$router.currentRoute.hash;
                if(hash){                    
                    let pos = document.getElementById(hash.split("#")[1]);
                    let offset = document.getElementsByClassName("tehnologije-navbar")[0].offsetTop + 10;
                    
                    this.$smoothScroll({
                        scrollTo: pos,
                        offset: -offset
                    });
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    main{
        background-color: $light-3;
    }

    .tehnologije-navbar{
        position: sticky;
        top: 0;
        z-index: 900;
        margin-bottom: 2rem;
        padding-bottom: 0!important;
    }

    h1{
        font-size: 2.7rem;
        align-self: flex-start;
        margin-left: 3rem;
        margin-bottom: 1.5rem;
    }

    //**********RESPONSIVE************//
    .laptopL{
        h1{font-size: 2.5rem}
    }
    
    .laptop{
        h1{font-size: 2rem}
    }

    .tablet{

        .tehnologije-navbar{   
            top: 3.75rem;
            margin-bottom: 1.5rem;
        }

        h1{
            font-size: 1.8rem;
            margin-left: 1.5rem;
        }
    }

    .phone{
        padding-bottom: 1.2rem;

        .tehnologije-navbar{    
            top: 3.75rem;      
            margin-bottom: 1.2rem;      
        }

        h1{
            font-size: 1.5rem;
            margin-left: 1.2rem;
            margin-bottom: 1rem;
        }
    }

    .phoneS{
        padding-bottom: 1rem;

        .tehnologije-navbar{   
            top: 3.75rem;    
            margin-bottom: 1rem;                   
        }

        h1{
            font-size: 1.3rem;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }
</style>