<template>
    <main >

        <h3 class="title">{{this.model.title}}</h3>

        <h4>{{this.model.sub_title}}</h4>

        <hr>

        <div class="products grid-5">
            <pumpa class="flex-column" v-for="pumpa in pumpe" :key="pumpa.id" :pumpa="pumpa">

            </pumpa>
        </div>

    </main>
</template>

<script>
    import PumpaComponent from '@/components/pumpe/PumpaComponent.vue'

    export default {
        name: 'model',
        components:{
            'pumpa': PumpaComponent
        },

        props: [
            'model'
        ],

        data(){
            return{
                pumpe: []
            }
        },

        mounted(){
            this.fetch("pumps", null, this.model.id).then((response) => {
                this.pumpe = response.data.data;
            })
        }
    }
</script>

<style lang="scss" scoped>

    h3{
        color: $dark-1;
        font-size: 1.8rem;
        margin-top: 1.2rem;
    }

    h4{
        color: $dark-1;
        font-size: 1.3rem;
        margin-bottom: 0.5rem;
    }

    hr{
        color: $primary-light;
    }

    .products{
        grid-gap: 1rem;
    }

    //**********RESPONSIVE************//
    .laptopL{
        h3{
            font-size: 1.7rem;
            margin-top: 1.1rem;
        }

        h4{
            font-size: 1.2rem;
            margin-bottom: 0.3rem;
        }

        .products{
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .laptop{
        h3{
            font-size: 1.5rem;
            margin-top: 1rem;
        }

        h4{
            font-size: 1.1rem;
            margin-bottom: 0.3rem;
        }

        .products{
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .tablet{
        h3{
            font-size: 1.4rem;
            margin-top: 1rem;
        }

        h4{
            font-size: 1.05rem;
            margin-bottom: 0.3rem;
        }

        .products{
            grid-template-columns: repeat(3, 1fr);
        }    
    }

    .phone{

        h3{
            font-size: 1.2rem;
            margin-top: 0.9rem;
        }

        h4{
            font-size: 0.95rem;
            margin-bottom: 0.3rem;
        }

        .products{
            grid-template-columns: repeat(2, 1fr);
        }      
    }

    .phoneS{
        h3{
            font-size: 1.1rem;
            margin-top: 1rem;
        }

        h4{
            font-size: 0.85rem;
            margin-bottom: 0.3rem;
        }

        .products{
            grid-template-columns: repeat(1, 1fr);
        }          
    }
</style>