<template>
    <div class="servis" :class="$mq">


        <header class="flex-column">
            <div class="header-text flex-column">

                <i class="fas fa-tools"></i>

                <h1>Servis vakuum pumpi i usluge</h1>

                <p>
                    Univerzal Komerc poseduje preko 20 godina iskustva sa servisom vakuum pumpi. Od više tipova servisa, sve do stručnih konsultacija i instalacije. Naša visoko stručna služba pruža širok asortiman usluga, unutar firme, kao i na terenu. 
                </p>

            </div>
        </header>


        <main class="servis-items grid-3">

            <div class="servis-item">
                <h2><i class="far fa-calendar-check"></i>Redovan servis</h2>
                <p>Po Vašem zahtevu sklapamo ugovor o redovnom servisu i održavanju Vaših vakuum pumpi. </p>
            </div>

            <div class="servis-item">
                <h2><i class="fas fa-shipping-fast"></i>Terenska služba</h2>
                <p>U svakom momentu spremna da po Vašem pozivu dođe i na licu mesta dijagnostikuje i otkloni kvar. </p>
            </div>

            <div class="servis-item">
                <h2><i class="fas fa-redo"></i>Reparacija vakuum pumpi</h2>
                <p>Ukoliko Vaše vakuum pumpe više ne zadovoljavaju vaše potrebe, vršimo kompletnu reparaciju uz garanciju. </p>
            </div>

            <div class="servis-item">
                <h2><i class="fas fa-forward"></i>Instalacija i puštanje u rad</h2>
                <p>Prilikom kupovine nove vakuum pumpe možemo je instalirati i osposobiti za rad. </p>
            </div>

            <div class="servis-item">
                <h2><i class="far fa-comments"></i>Stručne konsultacije</h2>
                <p>Ukoliko imate bilo kakve nedoumice u vezi ispravnog održavanja, upotrebe ili primene vakuum pumpi, naša služba je tu da pruži odgovore. </p>
            </div>

            <div class="servis-item contact bg-dark">
                <h3>Dodatne informacije i zakazivanje:</h3>
                <p><i class="fas fa-mobile-alt"></i><a href="tel:+38163587439">+381 (063) 587-439</a></p>
                <p><i class="far fa-envelope"></i><a href="mailto:univerzalkomerc@gmail.com">univerzalkomerc@gmail.com</a></p>
                <router-link tag="a" to="/kontakt/">Kontakt forma</router-link>
            </div>

        </main>


    </div>
</template>

<script>
    export default {
        name: 'Servis',
        metaInfo: {
            title: 'Servis vakuum pumpi',
            meta: [
                {name: 'description', content: 'Od više tipova servisa, sve do stručnih konsultacija i instalacije. Naša visoko stručna služba pruža širok asortiman usluga, unutar firme, kao i na terenu. '}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.vakumpumpe.com/servis/'}
            ]
        },
    }
</script>

<style lang="scss" scoped>
    .servis{
        background-color: $light-1;
    }

    //**********TOP************//
    header{    
        justify-content: flex-end;
        height: 60vh;
        background: url('../assets/img/servis/servis-banner.jpg') no-repeat center/cover;
    }

    .header-text{
        justify-content: center;
        width: 50%;
        padding: 2rem;
        background-color: $light-3;
        color: $dark-1;
        border-radius: 3px 3px 0 0;

        i{
            color: $primary-orange;
            font-size: 4rem;
        }

        h1{
            color: $dark-1;
            text-align: center;
            font-size: 2.7rem;
            margin-bottom: 0.8rem;
        }

        p{
            color: $dark-1;
            font-size: 1.2rem;
            font-weight: 500;
            text-align: center;
        }
    }


    //**********SERVIS I USLUGE************//
    .servis-items{
        background-color: $light-3;
        padding: 2rem;
    }

    .servis-item{
        background-color: $light-1;
        min-height: 20vh;
        padding: 2rem;
        margin: 1.3rem;
        box-sizing: border-box;
        border-radius: 5px;

        i{
            color: $primary-orange;
            margin-right: 0.5rem;
        }

        h2{
            color: $dark-2;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }

        p{
            color: $dark-2;
            font-size: 1.1rem;
            font-weight: 500;
        }
    }

    .contact{
        background-color: $dark-1;
        padding: 1.5rem 2rem;
        box-sizing: border-box;
        border-radius: 5px;

        h3{
            font-size: 1.5rem;
            font-weight: 600;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;
            border-bottom: 2px solid $dark-4;
        }

        p{
            font-size: 1.1rem;
            font-weight: 500;
            color: $light-2;
            margin-bottom: 1rem;
            i{
                color: $primary-orange;
                margin-right: 0.8rem
            }
            a{border-bottom: hidden;}
        }

        a{
            color: $light-3;
            font-size: 1.1rem;
            font-weight: 500;
            border-bottom: 2px solid $primary-orange;
        }
    } 


    //**********RESPONSIVE************// 
    .laptopL{
        header{    
            height: 60vh;
        }

        .header-text{    
            width: 55%;

            i{
                font-size: 3rem;
            }

            h1{
                font-size: 2.5rem;
            }

            p{
                font-size: 1.2rem;
            }
        }

        .servis-items{
            padding: 1.5rem;
        }

        .servis-item{
            min-height: 20vh;
            padding: 1.5rem;
            margin: 1rem;

            h2{
                font-size: 1.4rem;
            }

            p{
                font-size: 1.05rem;
            }
        }

        .contact{
            padding: 1.5rem 2rem;

            h3{
                font-size: 1.4rem;
            }

            p{
                font-size: 1rem;
                margin-bottom: 1rem;
            }

            a{
                font-size: 1rem;
            }
        } 

    } 

    .laptop{
        header{
            height: 55vh;
        }

        .header-text{    
            width: 60%;
            padding: 1.7rem;

            i{
                font-size: 2.5rem;
            }

            h1{
                font-size: 2rem;
                margin-bottom: 0.5rem;
            }

            p{
                font-size: 1rem;
            }
        }

        .servis-items{
            padding: 1.5rem;
        }

        .servis-item{
            min-height: 20vh;
            padding: 1rem;
            margin: 0.9rem;

            h2{
                font-size: 1.3rem;
            }

            p{
                font-size: 0.9rem;
            }
        }

        .contact{
            padding: 1rem 1.5rem;

            h3{
                font-size: 1.3rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.9rem;
                margin-bottom: 0.7rem;
            }

            a{
                font-size: 0.9rem;
            }
        } 

    } 

    .tablet{
        margin-top: 60px;

        header{
            height: auto;
            padding-top: 7rem;
        }

        .header-text{    
            width: 80%;
            padding: 1rem;
            box-sizing: border-box;

            i{
                font-size: 2rem;
            }

            h1{
                font-size: 1.8rem;
                margin-bottom: 0.5rem;
            }

            p{
                font-size: 1rem;
            }
        }

        .servis-items{
            grid-template-columns: 1fr 1fr;
            padding: 1rem;
        }

        .servis-item{
            min-height: 20vh;
            padding: 0.8rem;
            margin: 0.7em;

            h2{
                font-size: 1.2rem;
                margin-bottom: 0.3rem
            }

            p{
                font-size: 0.9rem;
            }
        }

        .contact{
            padding: 0.8rem;

            h3{
                font-size: 1.1rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.7rem;
                margin-bottom: 0.7rem;
                i{margin-right: 0.35rem;}
            }

            a{
                font-size: 0.7rem;
            }
        } 

    } 

    .phone{
        margin-top: 60px;

        header{
            height: auto;
            padding-top: 5rem;
        }

        .header-text{    
            width: 90%;
            padding: 1rem;
            box-sizing: border-box;

            i{
                font-size: 1.8rem;
            }

            h1{
                font-size: 1.5rem;
                margin-bottom: 0.3rem;
            }

            p{
                font-size: 0.8rem;
            }
        }

        .servis-items{
            grid-template-columns: 1fr;
            padding: 1rem;
        }

        .servis-item{
            min-height: unset;
            padding: 0.8rem;
            margin: 0.5rem 0;

            h2{
                font-size: 1.1rem;
                margin-bottom: 0.2rem;
            }

            p{
                font-size: 0.8rem;
            }
        }

        .contact{
            padding: 1rem;

            h3{
                font-size: 1.1rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.8rem;
                margin-bottom: 0.7rem;
                i{margin-right: 0.5rem;}
            }

            a{
                font-size: 0.8rem;
            }
        } 
    } 

    .phoneS{
        margin-top: 60px;

        header{
            height: auto;
            padding-top: 4rem;
        }

        .header-text{    
            width: 90%;
            padding: 1rem;
            box-sizing: border-box;

            i{
                font-size: 1.3rem;
            }

            h1{
                font-size: 1.3rem;
                margin-bottom: 0.3rem;
            }

            p{
                font-size: 0.8rem;
            }
        }

        .servis-items{
            grid-template-columns: 1fr;
            padding: 1rem;
        }

        .servis-item{
            min-height: unset;
            padding: 0.8rem;
            margin: 0.5rem 0;

            h2{
                font-size: 1rem;
                margin-bottom: 0.3rem;
            }

            p{
                font-size: 0.8rem;
            }
        }

        .contact{
            padding: 1rem;

            h3{
                font-size: 1rem;
                padding-bottom: 0.3rem;
            }

            p{
                font-size: 0.7rem;
                margin-bottom: 0.7rem;
                i{margin-right: 0.5rem;}
            }

            a{
                font-size: 0.7rem;
            }
        } 
    } 

</style>