<template>
    <div id="app">

        <navbar></navbar>

        <router-view class="router-view" :key="$route.fullPath"></router-view>

        <footer-component></footer-component>

    </div>
</template>

<script>
import Navbar from '@/components/navbar/NavbarComponent.vue'
import Footer from '@/components/footer/FooterComponent.vue'

export default {
  name: 'app',
  metaInfo: {
      title: 'Univerzal komerc',
      titleTemplate: '%s | Univerzal komerc' ,
  },
  components: {
    'navbar': Navbar,
    'footer-component': Footer
  }
}

</script>

<style>

  html{    
    overflow-x: hidden;
  }

  #app{
    background-color: #e5e5e5;
  }

  .router-view{
    min-height: calc(100vh - 120px);
  }
</style>
