<template>
    <main class="aplikacije grid-3 bg-light" :class="$mq">
        <aplikacija-small class="aplikacija-small" v-for="aplikacija in aplikacije" :key="aplikacija.id" :aplikacija="aplikacija">

        </aplikacija-small>
    </main>
</template>

<script>
    import AplikacijaSmall from '@/components/aplikacije/AplikacijaSmallComponent.vue'

    export default {
        name: 'aplikacije',
        metaInfo () {
            return {
                title: 'Aplikacije',
                meta: [
                    {name: 'robots', content: 'noindex, follow'}
                ],
                link: [
                    {rel: 'canonical', href: 'https://www.vakumpumpe.com/aplikacije/'}
                ]
            }
        },
        
        components: {
            'aplikacija-small': AplikacijaSmall,
        },

        data(){
            return{
                aplikacije: []
            }
        },

        mounted(){
            this.fetch("applications").then((response) => {
                this.aplikacije = response.data.data;
            })        
        }
    }
</script>

<style lang="scss" scoped>
    .aplikacije{
        background-color: $light-3;
        width: calc(100% - 6rem);
        margin: 0 auto;
        padding: 3rem 0 7rem 0;
        grid-gap: 0.2rem;
    }

    .aplikacija-small{
        width: 100%;
        height: 17rem;
    }

    //**********RESPONSIVE************//

    .laptop{        

        .aplikacija-small{
            height: 15rem;
        }
    }

    .laptop{
        &.aplikacije{
            width: calc(100% - 3rem);
            padding: 2.5rem 0 5rem 0;
        }
    }

    .tablet{
        &.aplikacije{
            grid-template-columns: 1fr 1fr;
            width: calc(100% - 2rem);
            padding: 2rem 0 4rem 0;
            margin-top: 60px;
        }

        .aplikacija-small{
            height: 14rem;
            width: 100%;
        }
    }

    .phone{
        &.aplikacije{
            grid-template-columns: 1fr;
            width: calc(100% - 2rem);
            padding: 1.5rem 0 3rem 0;
            margin-top: 60px;
        }

        .aplikacija-small{
            height: 12rem;
        }
    }

    .phoneS{
        &.aplikacije{
            grid-template-columns: 1fr;
            width: calc(100% - 2rem);
            padding: 1.5rem 0 3rem 0;
            margin-top: 60px;
        }

        .aplikacija-small{
            height: 11rem;
        }
    }
</style>