<template>
    <main class="flex-row bg-orange" :class="$mq">
        <div class="error bg-light flex-column">
            <h3>Greška</h3>
            <p>404</p>
        </div>

        <div class="message">
            <p>Stranica koju tražite ne postoji.</p>
            <router-link class="btn btn-orange-outline" to="/">Nazad na početnu stranicu</router-link>
        </div>
    </main>
</template>

<script>
    export default {
        name: 'not-found',
        metaInfo () {
            return {
                title: "404 - Not Found",
                meta: [
                    {name: 'robots', content: 'noindex, nofollow'}
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    main{
        width: 100%;
        height: calc(100vh - 130px);
        justify-content: center;
    }

    .error{
        padding: 3.5rem;

        h3{
            font-size: 4rem;
        }
        
        p{
            font-size: 10rem;
            font-weight: 600;
        }
    }

    .message{
        padding: 3.5rem;

        p{
            font-size: 2.5rem;
            font-weight: 600;
            text-align: center;
        }

        .btn{
            font-size: 1.5rem;
            width: auto;
            padding: 0.5rem;
            cursor: pointer;
            margin-top: 2rem;
        }

        .btn:hover{border-color: $primary-light;}
    }


    //**********RESPONSIVE************// 
    .laptop{
        &.bg-orange{
            justify-content: space-evenly;            
            height: calc(100vh - 100px);
        }
    }

    .tablet, .phone{
        &.bg-orange{
            flex-direction: column;
            height: calc(100vh - 111px);
        }

        .error{
            padding: 2rem;

            h3{font-size: 2.5rem;}
            p{font-size: 6rem;}
        }

        .message{
            padding: 2rem;

            p{font-size: 1.7rem}
            .btn{font-size: 1.3rem;}
        }
    }

    .phoneS{
        &.bg-orange{
            flex-direction: column;
            height: calc(100vh - 111px);
        }

        .error{
            padding: 2rem;

            h3{font-size: 2rem;}
            p{font-size: 4rem;}
        }

        .message{
            padding: 2rem;

            p{font-size: 1.5rem}
            .btn{font-size: 1.2rem;}
        }
    }
</style>