<template>


    <main class="flex-column bg-light" :class="$mq">

        <serije-navbar class="serije-navbar"></serije-navbar>

        <h1>Prodaja vakuum pumpi</h1>

        <serija-small v-for="serija in serije" :key="serija.id" :serija="serija" :id="`${serija.route_param}`">

        </serija-small>

    </main>


</template>

<script>
    import SerijaSmallComponent from '@/components/pumpe/SerijaSmallComponent.vue';
    import SerijeNavbarComponent from '@/components/pumpe/SerijeNavbarComponent.vue';
    
    export default {
        name: 'serije',
        metaInfo: {
            title: 'Prodaja vakuum pumpi',
            meta: [
                {name: 'description', content: 'Prodaja Busch vakuum pumpi. Dostupne sve serije i modeli iz Busch ponude. '}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.vakumpumpe.com/prodaja/'}
            ]
        },

        components: {
            'serija-small': SerijaSmallComponent,
            'serije-navbar': SerijeNavbarComponent
        },

        data(){
            return{
                serije: []
            }
        },

        mounted(){
            this.fetch("series").then((response) => {
                this.serije = response.data.data;
            })  
        },
    }
</script>

<style lang="scss" scoped>
    main{
        background-color: $light-3;
        padding-bottom: 2rem;
    }

    .serije-navbar{
        position: sticky;
        top: 0;
        z-index: 900;
        margin-bottom: 2rem;
        padding-bottom: 0!important;
    }

    h1{
        font-size: 2.7rem;
        align-self: flex-start;
        margin-left: 3rem;
        margin-bottom: 1.5rem;
    }

    //**********RESPONSIVE************//
    .laptopL{
        h1{font-size: 2.5rem}
    }
    
    .laptop{
        h1{font-size: 2rem}
    }

    .tablet{

        .serije-navbar{
            top: 3.75rem;
        }

        h1{
            font-size: 1.8rem;
            margin-left: 1.5rem;
        }
    }

    .phone{
        padding-bottom: 0.5rem;

        .serije-navbar{
            top: 3.75rem;
            margin-bottom: 1rem;
        }

        h1{
            font-size: 1.5rem;
            margin-left: 1.2rem;
            margin-bottom: 1rem;
        }
    }

    .phoneS{
        padding-bottom: 0.5rem;

        .serije-navbar{
            top: 3.75rem;
            margin-bottom: 1rem;
        }

        h1{
            font-size: 1.3rem;
            margin-left: 1rem;
            margin-bottom: 1rem;
        }
    }
</style>