var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servis",class:_vm.$mq},[_vm._m(0),_c('main',{staticClass:"servis-items grid-3"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"servis-item contact bg-dark"},[_c('h3',[_vm._v("Dodatne informacije i zakazivanje:")]),_vm._m(6),_vm._m(7),_c('router-link',{attrs:{"tag":"a","to":"/kontakt/"}},[_vm._v("Kontakt forma")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"flex-column"},[_c('div',{staticClass:"header-text flex-column"},[_c('i',{staticClass:"fas fa-tools"}),_c('h1',[_vm._v("Servis vakuum pumpi i usluge")]),_c('p',[_vm._v(" Univerzal Komerc poseduje preko 20 godina iskustva sa servisom vakuum pumpi. Od više tipova servisa, sve do stručnih konsultacija i instalacije. Naša visoko stručna služba pruža širok asortiman usluga, unutar firme, kao i na terenu. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servis-item"},[_c('h2',[_c('i',{staticClass:"far fa-calendar-check"}),_vm._v("Redovan servis")]),_c('p',[_vm._v("Po Vašem zahtevu sklapamo ugovor o redovnom servisu i održavanju Vaših vakuum pumpi. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servis-item"},[_c('h2',[_c('i',{staticClass:"fas fa-shipping-fast"}),_vm._v("Terenska služba")]),_c('p',[_vm._v("U svakom momentu spremna da po Vašem pozivu dođe i na licu mesta dijagnostikuje i otkloni kvar. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servis-item"},[_c('h2',[_c('i',{staticClass:"fas fa-redo"}),_vm._v("Reparacija vakuum pumpi")]),_c('p',[_vm._v("Ukoliko Vaše vakuum pumpe više ne zadovoljavaju vaše potrebe, vršimo kompletnu reparaciju uz garanciju. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servis-item"},[_c('h2',[_c('i',{staticClass:"fas fa-forward"}),_vm._v("Instalacija i puštanje u rad")]),_c('p',[_vm._v("Prilikom kupovine nove vakuum pumpe možemo je instalirati i osposobiti za rad. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"servis-item"},[_c('h2',[_c('i',{staticClass:"far fa-comments"}),_vm._v("Stručne konsultacije")]),_c('p',[_vm._v("Ukoliko imate bilo kakve nedoumice u vezi ispravnog održavanja, upotrebe ili primene vakuum pumpi, naša služba je tu da pruži odgovore. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"fas fa-mobile-alt"}),_c('a',{attrs:{"href":"tel:+38163587439"}},[_vm._v("+381 (063) 587-439")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"far fa-envelope"}),_c('a',{attrs:{"href":"mailto:univerzalkomerc@gmail.com"}},[_vm._v("univerzalkomerc@gmail.com")])])
}]

export { render, staticRenderFns }