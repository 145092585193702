<template>


    <footer class="container bg-dark"  :class="$mq">


        <div class="navigation flex-column">

            <h4>Sajt</h4>

            <hr>

            <ul class="flex-column">
                <router-link tag="li" to="/">Home</router-link>
                <router-link tag="li" to="/prodaja/">Vakuum pumpe</router-link>
                <router-link tag="li" to="/servis/">Servis</router-link>
                <router-link tag="li" to="/rezervnidelovi/svi-delovi/">Rezervni delovi</router-link>
                <router-link tag="li" to="/lamele/">Grafitne lamele</router-link>
            </ul>

        </div>


        <div class="contact flex-column">

            <h4>Kontakt</h4>

            <hr>

            <ul class="flex-column">
                <li class="flex-row">
                    <i class="fas fa-phone-alt"></i>
                    <p><a href="tel:+38163587439">+381 (063) 587-439</a></p>
                </li>
                <li class="flex-row">
                    <i class="fas fa-mobile-alt"></i>
                    <p><a href="tel:+381021463727">+381 (021) 463-727</a></p>
                </li>
                <li class="flex-row">
                    <i class="far fa-envelope"></i>
                    <p><a href="mailto:univerzalkomerc@gmail.com">univerzalkomerc@gmail.com</a></p>
                </li>
                <li class="flex-row">
                    <i class="fas fa-map-marker-alt"></i>
                    <div class="adress flex-column">                        
                        <p>Gajeva 59</p>
                        <p>21208, Sremska Kamenica</p>
                        <p>Republika Srbija</p>
                    </div>
                </li>
            </ul>

        </div>


        <p class="copyright">Univerzal Komerc doo. <span>{{currentYear}}</span></p>


    </footer>


</template>

<script>
export default {
    computed: {
        currentYear(){
            let date = new Date();
            return date.getFullYear();
        }
    }
}
</script>

<style lang="scss" scoped>
    //**********UOPSTENO************//
    footer{
        display: grid; display: -ms-grid;
        grid-template-areas: "nav con"
                             "copy copy";
        width: 100%;
        padding: 2rem 2rem 0 2rem;
        box-sizing: border-box;

    
        h4{
            font-size: 1.6rem;
        }

        hr{
            border: 1.5px solid $primary-orange;
            width: 55%;
            margin: 0.3rem 0;
        }
    }


    //**********NAVIGACIJA************//
    .navigation{
        grid-area: nav;
        align-items: flex-start;
        width: 35%;
        margin: 0 auto;

        ul{
            align-items: flex-start;
            margin-top: 0.5rem;

            li{
                font-size: 1.2rem;
                font-weight: 600;
                cursor: pointer;

                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }


    //**********KONTAKT I ADRESA************//
    .contact{
        grid-area: con;
        align-items: flex-start;
        width: 35%;
        margin: 0 auto;

        ul{
            align-items: flex-start;
            margin-top: 0.5rem;

            li{
                align-items: flex-start;
                margin-bottom: 0.5rem;
                font-size: 1rem;
                font-weight: 500;
                color: $light-2;

                a{
                    font-size: 1rem;
                    font-weight: 500;
                    color: $light-2;
                }

                i{
                    font-size: 0.9rem;
                    color: $primary-orange;
                    margin-right: 0.7rem;
                    margin-top: 0.2rem;
                }

                .adress{
                    align-items: flex-start;
                }
            }
        }
    }


    //**********DNO************//
    .copyright{
        font-size: 1rem;
        font-weight: 600;
        grid-area: copy;
        text-align: center;
        width: 100%;
        margin-top: 1rem;
        padding: 0.5rem 0;
        border-top: 1px solid $dark-2;
        word-spacing: 2px;

        span{margin-left: 3px}
    }


    //**********RESPONSIVE************//
    .laptopL{
        padding: 1.5rem 1.5rem 0 1.5rem;
    
        h4{
            font-size: 1.4rem;
        }

        hr{
            margin: 0.2rem;
        }

        .navigation{
            width: 40%;

            ul{

                li{
                    font-size: 1.1rem;
                }
            }
        }

        .contact{
            width: 40%;

            ul{

                li{
                    font-size: 0.9rem;
                    margin-bottom: 0.5rem;

                    a{
                        font-size: 0.9rem;
                    }

                    i{
                        font-size: 0.8rem;
                        margin-right: 0.6rem;
                        margin-top: 0.1rem;
                    }
                }
            }
        }

        .copyright{
            font-size: 0.9rem;
            padding: 0.3rem 0;
        }
    }

    .laptop{
        padding: 1rem 1rem 0 1rem;
    
        h4{
            font-size: 1.2rem;
        }

        hr{
            margin: 0.1rem;
        }

        .navigation{
            width: 40%;

            ul{

                li{
                    font-size: 1rem;
                }
            }
        }

        .contact{
            width: 40%;

            ul{

                li{
                    font-size: 0.8rem;
                    margin-bottom: 0.5rem;

                    a{
                        font-size: 0.8rem;
                    }

                    i{
                        font-size: 0.7rem;
                        margin-right: 0.6rem;
                        margin-top: 0.1rem;
                    }
                }
            }
        }

        .copyright{
            font-size: 0.8rem;
            padding: 0.2rem 0;
        }
    }

    .tablet{
        grid-template-areas: "con con"
                             "copy copy";
        padding: 1rem 1.5rem 0 1.5rem;
    
        h4{
            font-size: 1rem;
        }

        hr{
            margin: 0.1rem;
        }

        .navigation{
            display: none;
        }

        .contact{
            width: 100%;
            margin-top: 1rem;

            ul{

                li{
                    font-size: 0.75rem;
                    margin-bottom: 0.5rem;

                    a{
                        font-size: 0.75rem;
                    }

                    i{
                        font-size: 0.7rem;
                        margin-right: 0.6rem;
                        margin-top: 0.1rem;
                    }
                }
            }
        }

        .copyright{
            font-size: 0.7rem;
            padding: 0.2rem 0;
        }
    }

    .phone{
        grid-template-areas: "con con"
                             "copy copy";
        padding: 1rem 1rem 0 1rem;
    
        h4{
            font-size: 0.9rem;
        }

        hr{
            margin: 0.1rem;
        }

        .navigation{
            display: none;
        }

        .contact{
            width: 100%;
            margin-top: 0;

            ul{

                li{
                    font-size: 0.75rem;
                    margin-bottom: 0.5rem;

                    a{
                        font-size: 0.75rem;
                    }

                    i{
                        font-size: 0.7rem;
                        margin-right: 0.6rem;
                        margin-top: 0.1rem;
                    }
                }
            }
        }

        .copyright{
            font-size: 0.7rem;
            padding: 0.2rem 0;
            margin-top: 0;
        }
    }

    .phoneS{
        grid-template-areas: "con con"
                             "copy copy";
        padding: 1rem 1rem 0 1rem;
    
        h4{
            font-size: 0.8rem;
        }

        hr{
            margin: 0.1rem;
        }

        .navigation{
            display: none;
        }

        .contact{
            width: 100%;
            margin-top: 0;

            ul{

                li{
                    font-size: 0.7rem;
                    margin-bottom: 0.5rem;

                    a{
                        font-size: 0.7rem;
                    }

                    i{
                        font-size: 0.65rem;
                        margin-right: 0.6rem;
                        margin-top: 0.1rem;
                    }
                }
            }
        }

        .copyright{
            font-size: 0.65rem;
            padding: 0.2rem 0;
            margin-top: 0;
        }
    }
</style>