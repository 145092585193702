<template>
    <router-link tag="a" :to="`/prodaja/${this.serija.route_param}/`" :class="$mq" class="flex-row bg-light">


        <div class="about flex-column">

            <h2>{{this.serija.title}}</h2>

            <h3>{{this.serija.type}}</h3>

            <p class="desc">{{this.serija.desc_short}}</p>

            <hr>

            <div class="data flex-row">
                <p v-if="this.serija.pressure != ''">Ultimativni pritisak: <span v-html="this.serija.pressure"></span></p>
                <p v-if="this.serija.speed != ''">Nominalni kapacitet: {{this.serija.speed}}</p>
            </div>

        </div>



        <img :src="`${img()}`" :alt="`${this.serija.title} pumpa`">


    </router-link>
</template>

<script>
    export default {
        name: 'serijaSmall',

        props: ['serija'],

        methods: {
            img(){
                return require("../../assets/img/pumpe/large/"+this.serija.img)
            },   
        }
    }
</script>

<style lang="scss" scoped>
    a{
        background-color: $light-1;
        width: 90%;
        padding: 2rem 2.5rem;
        margin-bottom: 2rem;
        box-sizing: border-box;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        .about{
            align-items: flex-start;
            width: 70%;

            h2{
                text-transform: uppercase;
                font-size: 2.5rem;
                transition: all 0.3s ease-in-out;
            }

            h3{
                font-size: 1.8rem;
                margin-top: 0.3rem;
            }

            .desc{
                color: $dark-3;
                font-size: 1.2rem;
                font-weight: 600;
                margin-top: 1rem;
                transition: all 0.3s ease-in-out;
            }

            hr{
                width: 100%;
                border-bottom-width: 2px;
                border-color: $light-3;
                background-color: $light-3;
                color: $light-3;
                border-left: 0;
                border-top-width: 0;
                margin: 1rem 0 0.5rem 0;
                transition: 0.5s ease-in-out;
            }

            .data{
                justify-content: center;
                width: 100%;

                p{
                    color: $dark-3;
                    font-size: 1rem;
                    font-weight: 600;
                    margin: 0 0.7rem;
                    transition: all 0.3s ease-in-out;
                }
            }
        }

        img{
            width: 15%;
            margin-left: auto;
        }

        &:hover{
            background-color: $primary-orange;

            h2{
                color: $light-1
            }

            .desc{
                color: $dark-2;
            }

            .data{

                p{
                    color: $dark-2;
                }
            }
        }
    }


    //**********RESPONSIVE************//
    .laptopL{
        padding: 1.5rem 2rem;

        .about{

            h2{
                font-size: 2rem;
            }

            h3{
                font-size: 1.5rem;
            }

            .desc{
                font-size: 1.1rem;
                margin-top: 0.5rem;
            }

            hr{
                margin-top: 1rem;
            }

            .data{
                p{
                    font-size: 1rem;
                }
            }
        }
    }

    .laptop{
        padding: 1.3rem 1.8rem;

        .about{

            h2{
                font-size: 1.8rem;
            }

            h3{
                font-size: 1.3rem;
                margin-top: 0.2rem;
            }

            .desc{
                font-size: 1rem;
                margin-top: 0.5rem;
            }

            hr{
                margin-top: 0.7rem;
            }

            .data{
                p{
                    font-size: 0.9rem;
                }
            }
        }
    }

    .tablet{
        width: 90%;
        padding: 1rem;
        flex-direction: column-reverse;
        background-color: $light-2;
        border-radius: 2px;

        .about{
            width: 100%;

            h2{
                font-size: 1.5rem;
            }

            h3{
                font-size: 1.1rem;
                margin-top: 0.2rem;
            }

            .desc{
                font-size: 0.9rem;
                margin-top: 0.5rem;
            }

            hr{
                margin-top: 0.7rem;
            }

            .data{
                flex-direction: column;
                align-items: flex-start;

                p{
                    font-size: 0.8rem;
                    margin: 0;
                }
            }
        }

        img{
            height: 8rem;
            width: auto;
            margin: 0 auto 1rem auto;
        }
    }

    .phone{
        width: 90%;
        padding: 1rem;
        margin-bottom: 1.5rem;
        flex-direction: column-reverse;
        background-color: $light-2;
        border-radius: 2px;

        .about{
            width: 100%;

            h2{
                font-size: 1.3rem;
            }

            h3{
                font-size: 1rem;
                margin-top: 0.2rem;
            }

            .desc{
                font-size: 0.75rem;
                margin-top: 0.5rem;
            }

            hr{
                margin-top: 0.7rem;
            }

            .data{
                flex-direction: column;
                align-items: flex-start;

                p{
                    font-size: 0.7rem;
                    margin: 0;
                }
            }
        }

        img{
            height: 5rem;
            width: auto;
            margin: 0 auto 1rem auto;
        }
    }

    .phoneS{
        width: 90%;
        padding: 1rem;
        margin-bottom: 1.5rem;
        flex-direction: column-reverse;
        background-color: $light-2;
        border-radius: 2px;

        .about{
            width: 100%;

            h2{
                font-size: 1.1rem;
            }

            h3{
                font-size: 0.9rem;
                margin-top: 0.2rem;
            }

            .desc{
                font-size: 0.7rem;
                margin-top: 0.5rem;
            }

            hr{
                margin-top: 0.7rem;
            }

            .data{
                flex-direction: column;
                align-items: flex-start;

                p{
                    font-size: 0.65rem;
                    margin: 0;
                }
            }
        }

        img{
            height: 4rem;
            width: auto;
            margin: 0 auto 1rem auto;
        }
    }
</style>