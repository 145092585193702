<template>
    <div class="serija" :class="$mq">



        <main class="top flex-row">


            <div class="about">

                <div class="title flex-column">
                    <h1>{{this.serija.title}}</h1>
                    <h2>{{this.serija.type}}</h2>
                </div>

                <p v-html="this.serija.desc"></p>

            </div>


            <div class="image flex-column">

                <div class="image-data flex-column">
                    <img :src="`${img()}`" :alt="`${this.serija.title} pumpa`">

                    <p v-if="this.serija.pressure != ''">Ultimativni pritisak: <span v-html="this.serija.pressure"></span></p>
                    <p v-if="this.serija.speed != ''">Nominalni kapacitet: {{this.serija.speed}}</p>
                </div>

                <div class="contact">
                    <h3>Dodatne informacije:</h3>
                    <p><i class="fas fa-mobile-alt"></i><a href="tel:+38163587439">+381 (063)587-439</a></p>
                    <p><i class="far fa-envelope"></i><a href="mailto:univerzalkomerc@gmail.com">univerzalkomerc@gmail.com</a></p>
                    <router-link tag="a" to="/kontakt/">Kontakt forma</router-link>
                </div>

            
                
            </div>


        </main>



        <div class="products">
            <model v-for="model in modeli" :key="model.id" :model="model">

            </model>
        </div>



    </div>
</template>

<script>
    import ModelComponent from '@/components/pumpe/ModelComponent.vue'

    export default {
        name: 'serija',
        metaInfo () {
            return {
                title: this.title,
                meta: [
                    {name: 'description', content: this.serija.meta_description},
                    {name: 'robots', content: 'index, nofollow'}
                ],
                link: [
                    {rel: 'canonical', href: 'https://www.vakumpumpe.com/prodaja/'+this.serija_route+'/'}
                ]
            }
        },

        components: {
            'model': ModelComponent
        },

        props: [
            'serija_route'
        ],

        data(){
            return{
                serija: {},
                modeli: []
            }
        },

        mounted(){
            this.fetch("series", null, this.serija_route).then((response) => {
                this.serija = response.data.data;
            }).then(() => {
                this.fetch("pump-models", null, this.serija.id).then((response) => {
                    this.modeli = response.data.data;
                })
            })
        },

        methods: {
            img(){
                return require("../assets/img/pumpe/large/"+this.serija.img)
            }
        },

        computed: {
            title(){
                return this.serija.title + ' - ' + this.serija.type
            }
        }
    }
</script>

<style lang="scss" scoped>

    .serija{
        background-color: $light-3;
        padding: 2rem 0;
    }

    .top{
        background-color: $light-2;
        width: 90%;
        margin: 0 auto;
        padding: 2rem 3rem;
        box-sizing: border-box;
        border-radius: 3px;

        .about{
            flex-basis: 70%;
            align-self: flex-start;

            .title{
                align-items: flex-start;
                background-color: $primary-orange;
                width: fit-content;
                margin-left: -3rem;
                padding: 1rem 3rem;
                border-radius: 0 3px 3px 0;

                h1{
                    text-transform: uppercase;
                    color: $light-2;
                    font-size: 2.7rem;
                }

                h2{
                    color: $light-2;
                    font-size: 2.5rem;
                }
            }

            p{
                color: $dark-3;
                font-size: 1.1rem;
                font-weight: 600;
                width: 90%;
                margin-top: 1rem;
            }

            p /deep/ span{
                color: $dark-1;
                font-size: 1.25rem;
                font-weight: bold;
            }

            p /deep/ b{
                color: $primary-orange;
            }
        }
        
        .image{
            align-items: flex-start;
            flex-basis: 30%;
            margin: 0 auto;

            img{
                align-self: center;
                width: 92%;
                margin-top: 5rem;
            }

            .image-data p{
                font-size: 1.05rem;
                font-weight: 600;
                color: $dark-4;
                margin-top: 0.5rem;
            }

            .contact{
                background-color: $dark-1;
                padding: 1.5rem 2rem;
                margin-top: 3.5rem;
                box-sizing: border-box;
                border-radius: 5px;

                h3{
                    color: $light-1;
                    font-size: 1.3rem;
                    font-weight: 600;
                    padding-bottom: 0.5rem;
                    margin-bottom: 1rem;
                    border-bottom: 2px solid $dark-4;
                }

                p{
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: $light-2;
                    margin-bottom: 1rem;
                    i{
                        color: $primary-orange;
                        margin-right: 0.8rem
                    }
                    a{border-bottom: hidden;}
                }

                a{
                    color: $light-3;
                    font-size: 1.1rem;
                    font-weight: 600;
                    border-bottom: 2px solid $primary-orange;
                }
            }
        }
    }
    
    .products{
        margin: 0 auto;
        width: 86%;
    }

    //**********RESPONSIVE************//
    .laptopL{
        .top{
            padding: 2rem 2.5rem;

            .about{

                .title{
                    padding: 1rem 2.5rem;
                    margin-left: -2.5rem;

                    h1{
                        font-size: 2.5rem;
                    }

                    h2{
                        font-size: 2rem;
                    }
                }

                p{
                    width: 95%;
                    font-size: 1.1rem;
                }

                p /deep/ span{
                    font-size: 1.25rem;
                }
            }
            
            .image{

                img{
                    margin-top: 4rem;
                }

                .image-data p{
                    font-size: 1rem;
                }

                .contact{
                    padding: 1.5rem 2rem;
                    margin-top: 2.5rem;

                    h3{
                        font-size: 1.2rem;
                    }

                    p{
                        font-size: 0.95rem;
                        margin-bottom: 1.2rem;
                    }

                    a{
                        font-size: 0.95rem;
                    }
                }
            }
        }
    }

    .laptop{
        .top{
            padding: 1.5rem 2rem;

            .about{

                .title{
                    padding: 1rem 2rem;
                    margin-left: -2rem;

                    h1{
                        font-size: 2rem;
                    }

                    h2{
                        font-size: 1.8rem;
                    }
                }

                p{
                    font-size: 1rem;
                    width: 95%;
                }

                p /deep/ span{
                    font-size: 1.1rem;
                }
            }
            
            .image{

                img{
                    margin-top: 2rem;
                }

                .image-data p{
                    font-size: 0.85rem;
                }

                .contact{
                    width: 100%;
                    padding: 1rem 1.3rem;
                    margin-top: 2rem;

                    h3{
                        font-size: 1.1rem;
                        padding-bottom: 0.3rem;
                    }

                    p{
                        font-size: 0.8rem;
                        margin-bottom: 0.7rem;
                    }

                    a{
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    .tablet{
        padding: 1.5rem 0;
        margin-top: 60px;

        .top{
            flex-direction: column;
            width: 90%;
            padding: 1.5rem ;

            .about{
                flex-basis: 100%;

                .title{
                    padding: 0.8rem 1.5rem;
                    margin-left: -1.5rem;

                    h1{
                        font-size: 1.8rem;
                    }

                    h2{
                        font-size: 1.5rem;
                    }
                }

                p{
                    font-size: 0.95rem;
                    width: 100%;
                }

                p /deep/ span{
                    font-size: 1.05rem;
                }
            }
            
            .image{
                align-items: center;

                img{
                    width: 70%;
                    margin-top: 2rem;
                }

                .image-data p{
                    font-size: 0.85rem;
                }

                .contact{
                    width: 100%;
                    padding: 1rem 1.5rem;
                    margin-top: 1.5rem;

                    h3{
                        font-size: 1.1rem;
                        padding-bottom: 0.3rem;
                    }

                    p{
                        font-size: 0.9rem;
                        margin-bottom: 0.7rem;
                        i{margin-right: 0.7rem;}
                    }

                    a{
                        font-size: 0.9rem;
                    }
                }
            }
        }

        .products{            
            width: calc(100% - 3rem);
        }
    }

    .phone{
        padding: 1.5rem 0;
        margin-top: 60px;

        .top{
            flex-direction: column;
            width: 95%;
            padding: 1rem 1.2rem;

            .about{
                flex-basis: 100%;

                .title{
                    padding: 0.5rem 1.2rem;
                    margin-left: -1.2rem;

                    h1{
                        font-size: 1.5rem;
                    }

                    h2{
                        font-size: 1.3rem;
                    }
                }

                p{
                    font-size: 0.85rem;
                    width: 100%;
                }

                p /deep/ span{
                    font-size: 0.95rem;
                }
            }
            
            .image{

                img{
                    width: 60%;
                    margin-top: 1.5rem;
                }

                .image-data p{
                    font-size: 0.8rem;
                }

                .contact{
                    width: 100%;
                    padding: 1rem;
                    margin-top: 1.5rem;

                    h3{
                        font-size: 1rem;
                        padding-bottom: 0.3rem;
                    }

                    p{
                        font-size: 0.8rem;
                        margin-bottom: 0.5rem;
                        i{margin-right: 0.5rem;}
                    }

                    a{
                        font-size: 0.8rem;
                    }
                }
            }
        }

        .products{            
            margin-top: 0;
            width: calc(100% - 2rem);
        }
    }

    .phoneS{
        padding: 1rem 0;
        margin-top: 60px;

        .top{
            flex-direction: column;
            width: 95%;
            padding: 1rem;

            .about{
                flex-basis: 100%;

                .title{
                    padding: 0.5rem 1rem;
                    margin-left: -1rem;

                    h1{
                        font-size: 1.3rem;
                    }

                    h2{
                        font-size: 1.1rem;
                    }
                }

                p{
                    font-size: 0.8rem;
                    width: 100%;
                }

                p /deep/ span{
                    font-size: 0.9rem;
                }
            }
            
            .image{

                img{
                    width: 50%;
                    margin-top: 1.2rem;
                }

                .image-data p{
                    font-size: 0.75rem;
                }

                .contact{
                    width: 100%;
                    padding: 1rem;
                    margin-top: 1.5rem;

                    h3{
                        font-size: 0.9rem;
                        padding-bottom: 0.3rem;
                    }

                    p{
                        font-size: 0.75rem;
                        margin-bottom: 0.5rem;
                        i{margin-right: 0.5rem}
                    }

                    a{
                        font-size: 0.75rem;
                    }
                }
            }
        }

        .products{            
            width: calc(100% - 2rem);
        }
    }
</style>