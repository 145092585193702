<template>
    <main>
        <top></top>

        <about></about>

        <examples id="aa"></examples>
    </main>
</template> 

<script>
    import Top from '@/components/lamele/LameleTopComponent.vue'
    import About from '@/components/lamele/LameleAboutComponent.vue'
    import Examples from '@/components/lamele/LameleExamplesComponent.vue'

    export default {
        name: 'Lamele',
        metaInfo: {
            title: 'Grafitne lamele za vakuum pumpe',
            meta: [
                {name: 'description', content: 'Prodaja zamenskih, Asein grafitnih lamela, vrhunskog kvaliteta, za sve tipove suvih vakuum pumpi. '}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.vakumpumpe.com/lamele/'}
            ]
        },

        components: {
            'top': Top,
            'about': About,
            'examples': Examples
        },
    }
</script>

<style lang="scss" scoped>
    
</style> 
