<template>
    <ul  :class="$mq"> 


        <div v-if="isLargeScreen" class="flex-row">

            <li v-for="tehnologija in tehnologije" :key="tehnologija.id" >
                <a class="noselect" @click="scroll(tehnologija.route_param)" >{{tehnologija.title_short}}</a>
            </li>

        </div>

        
        <div v-else @click="menuUpDown()" class="navbar-mobile flex-column"> 

            <li class="dropdown-top noselect">Idi na <i class="fas fa-arrow-down"></i></li>
            
            <div class="dropdown-navbar" v-if="show_dropdown">
                <li v-for="tehnologija in tehnologije" :key="tehnologija.id">
                    <a class="noselect" @click="scroll(tehnologija.route_param)" >{{tehnologija.title_short}}</a>
                </li>
            </div>

        </div>


    </ul>
</template>

<script>
    export default {
        name: 'tehnologije-navbar',

        data(){
            return{
                tehnologije: [],
                show_dropdown: false
            }
        },

        computed: {
            isLargeScreen(){
                if(this.$mq === 'desktop' || this.$mq === 'laptopL')
                    return true

                return false
            }
        },

        mounted(){
            this.fetch("technologies").then((response) => {
                this.tehnologije = response.data.data;
            })
        },

        methods: {

            scroll(hash){
                let offset = -60;
                if(window.pageYOffset == 0){
                    offset = -120;
                }

                let pos = document.getElementById(hash);

                this.$smoothScroll({
                    scrollTo: pos,
                    offset: offset
                });
            },

            menuUpDown(){
                this.show_dropdown = !this.show_dropdown
            }
        }
    }
</script>

<style lang="scss" scoped>
    ul{
        justify-content: space-evenly;
        background-color: $light-2;
        width: 100%;

        .flex-row{
            height: 3.5rem;
            justify-content: space-evenly;
            border: 1px solid $light-3;
            border-top-width: 0;
        }

        li{
            a{
                cursor: pointer;
                color: $dark-2;
                font-size: 0.9rem;
                font-weight: 600;

                &:hover{
                    color: $primary-orange;
                }
            }

            &::first-letter{
                text-transform: uppercase;
            }
        }       
    }


    //**********MOBILE NAVBAR************//
    .navbar-mobile{
        width: 100%;
        margin: 0 auto;
        text-align: center;

        li{
            cursor: pointer;
            color: $dark-2;
            font-weight: 600;
            padding: 0.4rem;
        }

        li:hover{
            color: $primary-orange;
        }

        li i{
            font-size: 0.9rem;
            color: $primary-orange;
        }

        .dropdown-top{
            line-height: 3.5rem;
            padding: 0;
        }

        .dropdown-navbar{
            z-index: 900;
            position: sticky;
            top: 3rem;
            background-color: $light-2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
    }


    //**********RESPONSIVE************//


    .laptopL{

        li{
            font-size: 1rem;

            i{
                font-size: 0.8rem;
            }

            a{
                font-size: 0.8rem;
            }
        }
    }  

    .laptop{

        li{
            font-size: 1rem;

            i{
                font-size: 0.9rem;
            }

            a{
                font-size: 0.9rem;
            }
        }
    }  

    .tablet{
        margin-top: 60px;

        .navbar-mobile{     
            margin:  0 auto;

            .dropdown-navbar{
                top: calc(4rem + 60px);
            }
        }

        li{
            font-size: 0.95rem;

            i{
                font-size: 0.8rem;
            }

            a{
                font-size: 0.85rem;
            }
        }
    }

    .phone{
        margin-top: 60px;

        .navbar-mobile{         
            margin: 0 auto;

            li{
                font-size: 0.85rem;

                i{
                    font-size: 0.7rem;
                }

                a{
                    font-size: 0.75rem;
                }
            }

            .dropdown-top{
                line-height: 3rem;
            }

            .dropdown-navbar{
                font-size: 1rem;
                top: calc(3rem + 60px);
            }
        }
    }

    .phoneS{
        height: 3rem;
        margin-top: 60px;

        .navbar-mobile{       
            width: 100%;         
            margin: 0 auto;

            li{
                font-size: 0.8rem;

                i{
                    font-size: 0.65rem;
                }

                a{
                    font-size: 0.7rem;
                }
            }

            .dropdown-top{
                line-height: 3rem;
                font-size: 0.9rem;
            }

            .dropdown-navbar{
                top: calc(3rem + 60px);
            }
        }
    }
</style>