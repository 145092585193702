<template>
    <div :class="$mq">



        <header>
            <div class="bg-tint flex-column">
                <h2>Rezervni delovi za vakuum pumpe </h2>
                <h3>(Porudžbine i dodatne informacije putem telefona ili mejla)</h3>
            </div>
        </header>

        

        <main class="flex-row">


            <div class="delovi flex-column">

                <div class="categories flex-row">
                    <div class="category flex-column noselect" @mouseenter="menuUpDown()" @mouseleave="menuUpDown()">
                        <span class="flex-row">   <h2>{{formatOdabrana(kategorija_route)}}</h2> <i class="fas fa-caret-down drop-arrow"></i></span>
                        <ul class="category-dropdown" v-if="show_dropdown">
                            <router-link tag="li" v-for="kategorija in kategorije" :key="kategorija.id" :to="`/rezervnidelovi/${formatRoute(kategorija.category)}/`">
                                {{formatKategorija(kategorija.category)}}
                            </router-link>
                        </ul>
                    </div>
                </div>

                <div class="items grid-5">
                    <li class="item flex-column" v-for="(deo, index) in this.delovi" :key="index">

                        <div class="top flex-column">
                            <div class="img-wrapper flex-column">                        
                                <img v-if="deo.img_url != ''" class="noselect" :src="`${img(index)}`" :alt="`${deo.name}`">
                                <i v-else class="fas fa-camera"></i>
                            </div>

                            <p>{{deo.name}}</p>
                        </div>

                        <hr>

                        <div class="bottom flex-column">
                            <p v-if="deo.code != ''">Kataloški br. {{deo.code}}</p>
                            <p v-else>Kataloški br. N/A</p>

                            <div class="item-bottom flex-row">
                                <i class="fas fa-phone-alt" @click="showPhone($event)"></i>
                                <p><a class="phone noselect" href="tel:+38163587439">+381 (063) 587-439</a></p>
                            </div>
                        </div>

                    </li>
                </div>

                <ul class="pagination flex-row">
                    <li v-for="n in this.pagination.last_page" :key="n" :class="{active:pagination.current_page == n}" @click="fetchDelovi(pagination.path+n)">{{n}}</li>
                </ul>

            </div>


        </main>



    </div>
</template>

<script>
    export default {
        name: 'rezervniDelovi',
        metaInfo () {
            return {
                title: this.title,
                meta: [
                    {name: 'description', content: (this.kategorija_route == 'svi-delovi') ? 'Svi rezervni delovi vodećih proizvođača vakuum pumpi. Lamele, filteri, ulja, dihtunzi i mnogi drugi. ' : this.kategorija.meta_description},
                    {name: 'robots', content: 'index, nofollow'}
                ],
                link: [
                    {rel: 'canonical', href: 'https://www.vakumpumpe.com/rezervnidelovi/'+this.kategorija_route+'/'}
                ]
            }
        },

        props: [
            'kategorija_route'
        ],

        data(){
            return{
                kategorije: [],
                id: "0",
                kategorija: {},
                delovi: [],
                pagination: {},
                show_dropdown: false
            }
        },

        mounted(){
            this.fetch("part-categories").then((response) => {
                this.kategorije = response.data.data;
            }).then(() => {
                this.getKategorija();
                this.fetchDelovi();
            })
        },

        computed: {
            isLargeScreen(){
                if(this.$mq === 'desktop' || this.$mq === 'laptopL' || this.$mq === 'laptop' )
                    return true

                return false
            },

            title(){
                switch(this.kategorija_route){
                    case 'svi-delovi':
                        return 'Svi delovi'
                    case 'lamele':
                        return 'Lamele'
                    case 'separatori':
                        return 'Separatori'
                    case 'filteri-ulja':
                        return 'Filteri ulja'
                    case 'filteri-vazduha':
                        return 'Filteri vazduha'
                    case 'ulje':
                        return 'Ulje'
                    case 'ležajevi-i-čaure':
                        return 'Ležajevi i čaure'
                    case 'semerinzi-i-o-ringovi':
                        return 'Semerinzi i o-ringovi'
                    case 'dihtunzi':
                        return 'Dihtunzi'
                    case 'vakuummetri':
                        return 'Vakuummetri'
                    case 'sredstva-za-čišćenje':
                        return 'Sredstva Za čišćenje'
                    case 'ostalo':
                        return 'Ostalo'
                    default:
                        return ''
                }
            }
        },

        methods: {
            getKategorija(){
                for(let i = 0; i < this.kategorije.length; i++){
                    let kategorija = this.kategorije[i].category.toLowerCase().split(' ').join('-');
                    if(kategorija == this.kategorija_route){
                        this.kategorija = this.kategorije[i]
                        this.id = this.kategorije[i].id;
                    }
                }
            },

            fetchDelovi(route=null){
                if(this.id != "0"){
                    let full_route = 'https://api.vakumpumpe.com/api/parts/'+this.id;
                    if(route != null){
                        full_route = route;
                    }
                    this.$http.get(full_route).then(response => {
                        this.delovi = response.data.data;
                        this.pagination = {
                            current_page: response.data.meta.current_page,
                            path: response.data.meta.path + "?page=",
                            last_page: response.data.meta.last_page
                        }
                    })
                }else{
                    this.$http.get('https://api.vakumpumpe.com/api/parts/random/18').then(response => {
                        this.delovi = response.data.data
                    })
                }
            },

            formatRoute(kategorija){////Formatira route parametar za url
                return kategorija.split(' ').join('-');
            },

            formatKategorija(kategorija){////Formatira prosledjenu kategoriju
                let str = "";

                for(let i = 0; i < kategorija.length; i++){
                    if(i == 0){
                        str += kategorija.charAt(i).toUpperCase();
                    }else if(kategorija.charAt(i-1) == ' ' && kategorija.charAt(i+1) != ' '){
                        str += kategorija.charAt(i).toUpperCase();
                    }else{
                        str += kategorija.charAt(i);
                    }
                }

                return str;
            },

            formatOdabrana(route){////Formatira odabranu kategoriju
                if(route == "semerinzi-i-o-ringovi"){
                    return "Semerinzi i O-ringovi";
                }

                let str = route.split('-').join(' ');
                let res = "";

                for(let i = 0; i < str.length; i++){
                    if(i == 0){
                        res += str.charAt(i).toUpperCase();
                    }else if(str.charAt(i-1) == ' ' && str.charAt(i+1) != ' '){
                        res += str.charAt(i).toUpperCase();
                    }else{
                        res += str.charAt(i);
                    }
                }

                return res;
            },

            img(i){////Kompajlira slike kako bi se mogle ucitati
                return require("../assets/img/delovi/"+this.delovi[i].img_url)
            },

            showPhone(){////Pokazuje broj telefona klikom na ikonicu
                event.target.parentElement.classList.toggle('show-phone');
                
                event.target.parentElement.lastChild.classList.toggle('noselect')
            },

            menuUpDown(){
                this.show_dropdown = !this.show_dropdown
            }

        }
        
    }
</script>

<style lang="scss" scoped>
    //**********NASLOV************//
    header{
        background: url('../assets/img/delovi/cover-delovi.jpg') no-repeat center/cover;
        height: 30vh;

        .bg-tint{        
            background-color: rgba(51, 51, 51, 0.678);
            height: 100%;
            justify-content: center;
            text-align: center;
            padding: 1rem;
            box-sizing: border-box;
        }

        h2{
            color: $primary-light;
            font-size: 2.7rem;
        }

        h3{
            color: $primary-light;
            font-size: 1.5rem;
        }
    }

    main{
        background-color: $light-3;
        align-items: flex-start;
        padding:  2rem;
        box-sizing: border-box;
        min-height: 53vh;
        width: 100%;

        .delovi{
            width: 100%;
            min-height: 100%;
            padding: 0 2rem;
            box-sizing: border-box;
            

            //**********KATEGORIJE************//
            .categories{
                width: 100%;
                margin-bottom: 2rem;  
            }

            .category{
                background-color: $light-1;
                width: 250px;
                padding: 0.5rem;
                box-sizing: border-box;

                span{            

                    h2{
                        color: $primary-orange;
                        font-size: 1.3rem;
                    }

                    i{
                        font-size: 1.4rem;
                        color: $text-dark;
                        margin-left: 0.5rem
                    }
                }

                .category-dropdown{
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    width: 250px;
                    margin-top: 2rem;
                    z-index: 1000;
                    background-color: $light-1;
                    border-top: 1px solid $light-grey-1;
                }

                .category-dropdown li{
                    text-align: center;
                    list-style: none;
                    color: $dark-3;
                    font-weight: 600;
                    font-size: 1.1rem;
                    padding: 0.5rem;
                    
                }

                .category-dropdown li:hover{
                    color: $primary-orange;
                }
            }

            .category:hover{
                cursor: pointer;
            }


            //**********PROIZVODI************//
            .items{
                grid-gap: 2rem;
                width: 100%;

                .item{
                    background-color: $light-2;
                    padding: 1rem;
                    border-radius: 5px;
                    max-height: 20rem;
                    height: auto;
                    justify-content: space-between;

                    .top{
                        height: 70%;
                        width: 100%;
                    }

                    .bottom{
                        width: 100%
                    }

                    .img-wrapper{
                        height: 9rem;
                        width: 100%;
                    }

                    img{
                        width: auto;
                        max-height: 7rem;
                        max-width: 75%;
                        margin: 1rem;
                    }

                    i{
                        font-size: 8rem;
                        color: $primary-orange;
                        margin-bottom: 1rem;
                    }

                    p{
                        color: $dark-2;
                        font-weight: 600;
                        align-self: flex-start;
                        font-size: 1rem;
                        word-break: break-word;
                    }

                    hr{
                        width: 1px;
                        border-color: $light-grey-1;
                        border-bottom-width: 1px;
                        border-left: 0;
                        border-top-width: 0;
                        width: 100%;
                        margin: 0.5rem 0;
                    }

                    .item-bottom{
                        width: 100%;
                        height: 1.5rem;
                        line-height: 1.5rem;
                        text-align: center;
                        margin-top: 1.2rem;
                        justify-content: center;
                        align-items: center;

                        i{
                            position:absolute;
                            color: $primary-orange;
                            cursor: pointer;
                            font-size: 1.2rem;
                            margin-top: 0.5rem;
                        }

                        a{
                            font-weight: 600;
                            pointer-events: none;
                            color: transparent;
                            margin: 0 0 0.8rem 0.5rem;
                        }
                    }
                }
            }

            //**********PAGINATION************//
            .pagination{
                margin-top: 3rem;
                background-color: $light-1;
                
                li{
                    cursor: pointer;
                    font-size: 1.1rem;
                    background-color: $light-1;
                    color: $dark-3;
                    font-weight: 600;
                    padding: 0.5rem 1rem 0.5rem 1rem;
                    transition: 0.2s ease-in-out;

                    &:hover{
                        background-color: $light-3;
                    }
                }

                li:first-of-type{border-left-width: 2px;}

                .active{
                    background-color: $primary-orange;
                    color: $light-1;
                    border-color: $primary-orange;                    

                    &:hover{
                        background-color: $primary-orange;
                    }
                }
            }
        }
    } 


    //**********MISC************//
    .show-phone{
        .fa-phone-alt{
            transform: translateX(-500%);
            transition: all 0.5s ease-in-out;
        }

        a{
            pointer-events: visible!important;
            color: $primary-orange!important;
            transition: all 0.8s ease-in-out;
        }

    }


    //**********RESPONSIVE************//
    .laptopL{
        header{
            height: 35vh;

            h2{
                font-size: 2rem;
            }

            h3{
                font-size: 1.3rem;
            }
        }

        main{
            padding:  2rem;

            .delovi{
                min-height: 100%;
                padding: 0 2rem;
                

                //**********KATEGORIJE************//
                .categories{
                    margin-bottom: 2rem;  
                }

                .category{
                    width: 250px;

                    span{            

                        h2{
                            font-size: 1.1rem;
                        }

                        i{
                            font-size: 1.2rem;
                        }
                    }

                    .category-dropdown{
                        width: 250px;
                        margin-top: 2rem;
                    }

                    .category-dropdown li{
                        font-size: 0.95rem;                        
                    }
                }


                //**********PROIZVODI************//
                .items{
                    grid-template-columns: repeat(4, 1fr);

                    .item{

                        p{
                            font-size: 0.9rem;
                        }

                        .item-bottom{

                            i{
                                font-size: 1.1rem;
                            }
                        }
                    }
                }

                //**********PAGINATION************//
                .pagination{
                    
                    li{
                        font-size: 1rem;
                    }
                }
            }
        } 

    }

    .laptop{
        header{
            height: 40vh;

            h2{
                font-size: 1.8rem;
            }

            h3{
                font-size: 1.2rem;
            }
        }

        main{
            padding:  1.5rem;

            .delovi{
                min-height: 100%;
                padding: 0 1.5rem;
                

                //**********KATEGORIJE************//
                .categories{
                    margin-bottom: 1.5rem;  
                }

                .category{
                    width: 220px;

                    span{            

                        h2{
                            font-size: 1rem;
                        }

                        i{
                            font-size: 1.1rem;
                        }
                    }

                    .category-dropdown{
                        width: 220px;
                        margin-top: 1.8rem;
                    }

                    .category-dropdown li{
                        font-size: 0.9rem;                        
                    }
                }


                //**********PROIZVODI************//
                .items{
                    grid-template-columns: repeat(3, 1fr);

                    .item{

                        .img-wrapper{
                            height: 8rem;
                        }

                        img{
                            max-height: 5rem;
                            max-width: 75%;
                        }

                        i{
                            font-size: 6rem;
                        }

                        p{
                            font-size: 0.9rem;
                        }

                        .item-bottom{

                            i{
                                font-size: 1.1rem;
                            }
                        }
                    }
                }

                //**********PAGINATION************//
                .pagination{
                    
                    li{
                        font-size: 0.9rem;
                    }
                }
            }
        } 

    }

    .tablet{
        header{
            margin-top: 60px;
            height: 40vh;

            h2{
                font-size: 1.5rem;
            }

            h3{
                font-size: 1.1rem;
            }
        }

        main{
            padding:  1.5rem;

            .delovi{
                min-height: 100%;
                padding: 0 ;
                

                //**********KATEGORIJE************//
                .categories{
                    margin-bottom: 1.5rem;  
                }

                .category{
                    width: 200px;

                    span{            

                        h2{
                            font-size: 0.9rem;
                        }

                        i{
                            font-size: 1rem;
                        }
                    }

                    .category-dropdown{
                        width: 200px;
                        margin-top: 1.7rem;
                    }

                    .category-dropdown li{
                        font-size: 0.8rem;                        
                    }
                }


                //**********PROIZVODI************//
                .items{
                    grid-template-columns: repeat(2, 1fr);

                    .item{

                        .img-wrapper{
                            height: 7rem;
                        }

                        img{
                            max-height: 3.5rem;
                            max-width: 75%;
                        }

                        i{
                            font-size: 5rem;
                        }

                        p{
                            font-size: 0.8rem;
                        }

                        .item-bottom{

                            i{
                                font-size: 1rem;
                            }
                        }
                    }
                }

                //**********PAGINATION************//
                .pagination{
                    
                    li{
                        font-size: 0.8rem;
                    }
                }
            }
        } 

    }

    .phone{
        header{
            margin-top: 60px;
            height: 30vh;

            h2{
                font-size: 1.3rem;
            }

            h3{
                font-size: 1rem;
            }
        }

        main{
            padding:  1.5rem 1rem;

            .delovi{
                min-height: 100%;
                padding: 0 ;
                

                //**********KATEGORIJE************//
                .categories{
                    margin-bottom: 1.2rem;  
                }

                .category{
                    width: 200px;

                    span{            

                        h2{
                            font-size: 0.85rem;
                        }

                        i{
                            font-size: 0.9rem;
                        }
                    }

                    .category-dropdown{
                        width: 200px;
                        margin-top: 1.7rem;
                    }

                    .category-dropdown li{
                        font-size: 0.75rem;                        
                    }
                }


                //**********PROIZVODI************//
                .items{
                    grid-template-columns: repeat(1, 1fr);

                    .item{

                        .img-wrapper{
                            height: 6rem;
                        }

                        img{
                            max-height: 3rem;
                            max-width: 75%;
                        }

                        i{
                            font-size: 4rem;
                        }

                        p{
                            font-size: 0.7rem;
                        }

                        .item-bottom{

                            i{
                                font-size: 0.9rem;
                            }
                        }
                    }
                }

                //**********PAGINATION************//
                .pagination{
                    
                    li{
                        font-size: 0.75rem;
                        padding: 0.3rem 0.7rem 0.3rem 0.7rem;
                    }
                }
            }
        } 
    }

    .phoneS{
        header{
            margin-top: 60px;
            height: 30vh;

            h2{
                font-size: 1.1rem;
            }

            h3{
                font-size: 0.8rem;
            }
        }

        main{
            padding: 1rem;

            .delovi{
                min-height: 100%;
                padding: 0;
                

                //**********KATEGORIJE************//
                .categories{
                    margin-bottom: 1.2rem;  
                }

                .category{
                    width: 170px;

                    span{            

                        h2{
                            font-size: 0.85rem;
                        }

                        i{
                            font-size: 0.9rem;
                        }
                    }

                    .category-dropdown{
                        width: 170px;
                        margin-top: 1.7rem;
                    }

                    .category-dropdown li{
                        font-size: 0.75rem;                        
                    }
                }


                //**********PROIZVODI************//
                .items{
                    grid-template-columns: repeat(1, 1fr);

                    .item{

                        .img-wrapper{
                            height: 6rem;
                        }

                        img{
                            max-height: 3rem;
                            max-width: 75%;
                        }

                        i{
                            font-size: 4rem;
                        }

                        p{
                            font-size: 0.7rem;
                        }

                        .item-bottom{

                            i{
                                font-size: 0.9rem;
                            }
                        }
                    }
                }

                //**********PAGINATION************//
                .pagination{
                    
                    li{
                        font-size: 0.65rem;
                        padding: 0.3rem 0.7rem 0.3rem 0.7rem;
                    }
                }
            }
        } 
    }
</style>