<template>
    <div @mouseenter="loadSeries()"  class="background" :style="{ backgroundImage: `url(${background})` }" :class="$mq">


        <router-link class="content flex-column" :to="`/aplikacija/${this.aplikacija.route_param}/`" >

            <h3><i :class="`${this.aplikacija.icon}`"></i>{{this.aplikacija.title}}</h3>

            <div class="hover-data flex-column">
                <h4>Odgovarajuće serije</h4>
                <ul class="flex-column" v-if="show_serije">
                    <li class="flex-row" v-for="(serija, index) in this.serije" :key="index">
                        <i class="fas fa-plus"></i>{{serija.title}}
                    </li>
                </ul>
                <div class="fake-button flex-row">
                    <span>Više informacija</span>
                    <i class="fas fa-caret-right"></i>
                </div>
            </div>

        </router-link>


    </div>
</template>

<script>
    export default {
        name: 'aplikacijaSmall',

        props: [
            'aplikacija'
        ],

        data(){
            return{
                serije: [],
                show_serije: false
            }
        },

        computed: {
            background () {
                return require("../../assets/img/aplikacije/bg/"+this.aplikacija.img)
            }
        },

        methods: {
            loadSeries(){                
                this.fetch("series", "application", this.aplikacija.id).then((response) => {
                    this.serije = response.data.data;
                    this.show_serije = true;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    //**********BACKGROUND IMAGE************//
    .background{
        display: flex;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 3px;
    }


    //**********CONTENT************//
    .content{
        position: relative;
        background-color: rgba(51, 51, 51, 0.6);
        color: $light-1;
        cursor: pointer;
        height: 17rem;
        width: 100%;
        box-sizing: border-box;
        padding: 1rem 1.5rem;
        margin: auto;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
        overflow: hidden;

        h3{
            font-size: 1.6rem;
            width: 100%;
            padding: 0.5rem;
            border-radius: 5px;
            background-image: linear-gradient(to right, rgba(223, 112, 0, 0.842), rgba(223, 112, 0, 0));
            transition: all 0.2s ease-in-out;

            i{
                color: $dark-1;
                margin-right: 1rem;
            }
        }

        .hover-data{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            justify-content: center;
            opacity: 0;
            transition: all 0.2s ease-in-out;

            h4{
                color: $primary-orange;
                font-size: 1.5rem;
            }

            ul{          
                text-transform: uppercase;
                color: $light-1;  
                margin-top: 0.3rem;
                font-size: 1.3rem;
                font-weight: 400;          
                
                i{
                    font-size: 0.8rem;
                    color: $primary-orange;
                    margin-right: 0.3rem; 
                    margin-top: 0.2rem;
                }
            }

            .fake-button{
                position: absolute;
                bottom: 1rem;
                right: 1rem;       
                font-size: 1.2rem;
                font-weight: 600;      
                color: $light-3;   
                transform: translateX(200%);
                transition: 0.1s ease-in-out;
                transition-delay: 0.2s;
                
                i{
                    font-size: 1.3rem;
                    color: $primary-orange;
                    margin-left: 0.5rem;
                    margin-top: 0.2rem;
                }
            }
        }
    }

    .content:hover{
        background-color: #333333e7;

        h3{
            opacity: 0;
        }

        .hover-data{
            opacity: 1;

            .fake-button{
                transform: translateX(0)
            }
        }
    }


    //**********RESPONSIVE************//
    .laptopL{
        h3{
            font-size: 1.4rem;
        }

        .hover-data{

            h4{
                font-size: 1.4rem;
            }

            ul{
                font-size: 1.2rem;        
                    
                i{
                    font-size: 0.9rem;
                }
            }

            .fake-button{
                font-size: 1.1rem;
                right: 0.7rem;
                bottom: 0.7rem;
                
                i{
                    font-size: 1.2rem;
                }
            }
        }
    }

    .laptop{
        .content{
            height: 15rem;
        }

        h3{
            font-size: 1.3rem;
        }

        .hover-data{

            h4{
                font-size: 1.3rem;
            }

            ul{
                font-size: 1.1rem;        
                    
                i{
                    font-size: 0.7rem;
                }
            }

            .fake-button{
                font-size: 1rem;
                right: 0.5rem;
                bottom: 0.5rem;
                
                i{
                    font-size: 1.1rem;
                }
            }
        }
    }

    .tablet{
        .content{
            height: 14rem;
        }

        h3{
            font-size: 1.2rem;
        }

        .hover-data{

            h4{
                font-size: 1.2rem;
            }

            ul{
                font-size: 1rem;        
                    
                i{
                    font-size: 0.6rem;
                }
            }

            .fake-button{
                font-size: 0.9rem;
                right: 0.5rem;
                bottom: 0.5rem;
                
                i{
                    font-size: 1rem;
                }
            }
        }
    }

    .phone{
        .content{
            height: 12rem;
        }

        h3{
            font-size: 1.1rem;
        }

        .hover-data{

            h4{
                font-size: 1.1rem;
            }

            ul{
                font-size: 0.9rem;        
                    
                i{
                    font-size: 0.55rem;
                }
            }

            .fake-button{
                font-size: 0.8rem;
                right: 0.5rem;
                bottom: 0.5rem;
                
                i{
                    font-size: 0.9rem;
                    margin-left: 0.3rem;
                }
            }
        }
    }

    .phoneS{
        .content{
            height: 11rem;
        }

        h3{
            font-size: 1rem;
        }

        .hover-data{

            h4{
                font-size: 1rem;
            }

            ul{
                font-size: 0.8rem;        
                    
                i{
                    font-size: 0.5rem;
                }
            }

            .fake-button{
                font-size: 0.7rem;
                right: 0.3rem;
                bottom: 0.3rem;
                
                i{
                    font-size: 0.8rem;
                    margin-left: 0.3rem;
                }
            }
        }
    }
</style>