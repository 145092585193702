<template>
    <main class="grid-4" :class="$mq">
        <router-link tag="a" :to="`/aplikacija/${aplikacija.route_param}/`" class="item flex-column" v-for="aplikacija in aplikacije" :key="aplikacija.id">
            <i :class="aplikacija.icon"></i>
        </router-link>
    </main>
</template>

<script>

    export default {
        name: 'expand-aplikacije',

        data(){
            return{
                aplikacije: []
            }
        },

        mounted(){
            this.fetch("applications").then((response) => {
                this.aplikacije = response.data.data;
            })        
        }
    }
</script>

<style lang="scss" scoped>
    main{
        width: 100%;
        height: 100%;

        .item{
            justify-content: center;

            i{
                font-size: 1.4rem;
                color: $dark-4;
                text-align: center;
                height: 4rem;
                line-height: 4rem;
            }

            i:hover{
                color: $primary-orange;
            }

        }
    }

    .laptopL{
        

        .item{

            i{
                font-size: 1.2rem;
                height: 3rem;
                line-height: 3rem;
            }
        }
    }

    .laptop{
        

        .item{

            i{
                font-size: 1.1rem;
                height: 2rem;
                line-height: 2rem;
            }
        }
    }
</style>