import Router from 'vue-router'
import Vue from 'vue'

import Home from '@/views/Home.vue'
import Tehnologije from '@/views/Tehnologije.vue'
import Tehnologija from '@/views/Tehnologija.vue'
import Serije from '@/views/Serije.vue'
import Aplikacije from '@/views/Aplikacije.vue'
import Aplikacija from '@/views/Aplikacija.vue'
import Serija from '@/views/Serija.vue'
import Servis from '@/views/Servis.vue'
import RezervniDelovi from '@/views/RezervniDelovi.vue'
import Lamele from '@/views/Lamele.vue'
import Contact from '@/views/Contact.vue'
import NotFound from '@/views/NotFound.vue'

Vue.use(Router)

const scrollBehavior = function (to, from, savedPosition) {
    return { x: 0, y: 0 };
}

const router = new Router({
    mode: 'history',
    scrollBehavior,
    routes: [
        {
            path: '*',
            component: NotFound
        },
        {
            path: "/",
            component: Home
        },
        {
            path: "/princip-rada/",//promenjeno iz tehnologije u princip rada, ali komponente i dalje imaju Tehnologije u imenu
            component: Tehnologije
        },
        {
            path: "/princip-rada/:tehnologija_route/",
            name: "tehnologija",
            component: Tehnologija,
            props: true
        },
        {
            path: "/prodaja/",
            component: Serije
        },
        {
            path: "/aplikacije/",
            component: Aplikacije
        },
        {//without param
            path: "/aplikacija/",
            redirect: "/aplikacija/elektronika"
        },
        {//with param
            path: "/aplikacija/:aplikacija_route/",
            name: "aplikacija",
            component: Aplikacija,
            props: true
        },
        {//with param
            path: "/prodaja/:serija_route/",
            name: "serija",
            component: Serija,
            props: true
        },
        {
            path: "/servis/",
            component: Servis
        },
        {//without param
            path: "/rezervnidelovi",
            redirect: "/rezervnidelovi/svi-delovi/"
        },
        {//with param
            path: "/rezervnidelovi/:kategorija_route/",
            name: "rezervnidelovi",
            component: RezervniDelovi,
            props: true
        },
        {
            path: "/lamele/",
            component: Lamele
        },
        {
            path: "/kontakt/",
            component: Contact
        },
        {
            path: "/not-found/",
            component: NotFound
        },
        {
            path: "/servis.html",
            redirect: '/servis/'
        },
        {
            path: "/pumpe.html",
            redirect: '/prodaja/'
        },
        {
            path: "/kontaktform.html",
            redirect: '/kontakt/'
        },
    ]
})

export default router