<template>
    <main :class="$mq">


        
        <div class="contact flex-row">


            <div class="info">
                <div class="bg-tint flex-column">

                    <div class="info-item flex-column">
                        <div class="item-header flex-row">
                            <i class="fas fa-address-card"></i>
                            <h4>Univerzal Komerc doo.</h4>
                        </div>
                    </div>

                    <div class="info-item flex-column">
                        <div class="item-header flex-row">
                            <i class="fas fa-mobile-alt"></i>
                            <h4>Mobilni</h4>
                        </div>
                        <p><a href="tel:+38163587439">+381 (063) 587-439</a></p>
                        <p><a href="tel:+38163587439">+381 (062) 9737-023</a></p>
                    </div>

                    <div class="info-item flex-column">
                        <div class="item-header flex-row">
                            <i class="fas fa-phone-alt"></i>
                            <h4>Telefon</h4>
                        </div>
                        <p><a href="tel:+38163587439">+381 (021) 463-727</a></p>
                    </div>

                    <div class="info-item flex-column">
                        <div class="item-header flex-row">
                            <i class="far fa-envelope"></i>
                            <h4>E-mail</h4>
                        </div>
                        <p><a href="mailto:univerzalkomerc@gmail.com">univerzalkomerc@gmail.com</a></p>
                    </div>

                    <div class="info-item flex-column">
                        <div class="item-header flex-row">
                            <i class="fas fa-map-marker-alt"></i>
                            <h4>Adresa</h4>
                        </div>
                        <div class="adress">
                            <p>Gajeva 59</p>
                            <p>21208, Sremska Kamenica</p>
                            <p>Republika Srbija</p>
                        </div>
                    </div>

                </div>
            </div>


            <div class="form flex-column bg-light">
                <h3>Kontaktirajte nas</h3>
                <!-- <form @submit.prevent="send_message()"  class="flex-column" novalidate> -->
                <form action="https://formsubmit.co/univerzalkomerc@gmail.com" method="POST"  class="flex-column" novalidate>

                    <div class="fullname flex-row">
                        <div class="form-item">
                            <span><label for="name">Ime *</label> <p class="error"></p></span> <input type="text" name="name" v-model="message_body.name"  placeholder="Unesite ime" required pattern="^[A-Za-z]+$" maxlength="30" @focusout="validate_name($event)"> 
                        </div>

                        <div class="form-item">
                            <span><label for="lastname">Prezime *</label> <p class="error"></p></span> <input type="text" name="lastname" v-model="message_body.lastname" placeholder="Unesite prezime" required pattern="^[A-Za-z]+$" maxlength="30" @focusout="validate_name($event)"> 
                        </div>
                    </div>

                    <div class="form-item">
                        <span><label for="email">E-mail *</label> <p class="error"></p></span> <input type="email" name="email" v-model="message_body.email" placeholder="Unesite e-mail adresu" required @focusout="validate_email($event)">
                    </div>

                    <div class="form-item">
                        <span><label for="phone">Br. telefona </label> <p class="error"></p></span> <input type="text" name="phone" v-model="message_body.phone" placeholder="Unesite vaš broj telefona" pattern="^\+?\d*$" maxlength="16" @focusout="validate_phone($event)">
                    </div>

                     <div class="form-item textarea">
                        <span><label for="msg">Poruka *</label> <p class="error"></p></span><textarea name="msg" v-model="message_body.msg" cols="50" rows="3" placeholder="Postavite nam pitanje" required maxlength="3000" @focusout="validate_msg($event)"></textarea>
                    </div>

                    <input type="submit" class="btn btn-orange" value="Pošalji"> 

                </form>
            </div>


        </div>



        <div class="location flex-column">
            <GmapMap
            :center="{lat:45.225905, lng:19.849812}"
            :zoom="14"
            map-type-id="roadmap"
            class="g-map"
            >
                <GmapMarker
                    :position="marker_position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                />
            </GmapMap>
        </div>



    </main>
</template>

<script>
    export default {
        name: 'contact',
        metaInfo() {
            return {
                title: 'Kontakt',
                meta: [
                    {name: 'description', content: 'Gajeva 59, Sremska Kamenica - Kontaktirajte nas putem kontakt forme ili putem telefona +381(063)587-439'}
                ],
                link: [
                    {rel: 'canonical', href: 'https://www.vakumpumpe.com/kontakt/'}
                ]
            }
        },

        data(){
            return{
                marker_position: {
                    lat: 45.225905,
                    lng:19.849812
                },

                message_body: {}                    
            }
        },

        mounted(){
            this.reset_form()
        },

        methods: {
            send_message(){
            this.$http.post('https://api.vakumpumpe.com/send_email', {
                full_name: this.message_body.name+" "+this.message_body.lastname,
                email: this.message_body.email,
                phone: this.message_body.phone,
                msg: this.message_body.msg
            }).then(
                this.reset_form()
            ).catch(err => console.log(err))
            },

            reset_form(){
                document.querySelector('.form form').reset(); 
            },

            validate_name(){ 
                const name = event.target;
                
                if (name.validity.valueMissing){
                    this.set_invalid(name, "Obavezno");
                    return false;
                }else{
                    this.set_valid(name);
                }    
                
                if(name.validity.patternMismatch){
                    this.set_invalid(name, "Greška");
                    return false;
                }else{
                    this.set_valid(name);
                }

                return true;
            },

            validate_email(){
                const email = event.target;

                if(email.validity.valueMissing){
                    this.set_invalid(email, "Obavezno");
                    return false;
                }else{
                    this.set_valid(email);
                } 
                
                if(email.validity.typeMismatch){
                    this.set_invalid(email, "Pogrešan format");
                    return false;
                }else{
                    this.set_valid(email);
                } 
                
                return true;
            },

            validate_phone(){
                const phone = event.target;
                
                if(phone.validity.patternMismatch){
                    this.set_invalid(phone, "Pogrešan format");
                    return false;
                }else{
                    this.set_valid(phone);
                }

                return true;
            },

            validate_msg(){
                const msg = event.target;

                if(msg.validity.valueMissing){
                    this.set_invalid(msg, "Obavezno");
                    return false;
                }else{
                    this.set_valid(msg);
                } 

                return true;
            },

            ////Pomocne funkcije
            set_invalid(field, message){
                let label = document.querySelector(`label[for=${field.name}]`);
                
                label.nextElementSibling.innerHTML = message;
            },

            set_valid(field){
                let label = document.querySelector(`label[for=${field.name}]`);

                label.nextElementSibling.innerHTML = '';
            }
        }
    }
</script>

<style lang="scss" scoped>


    .info{
        width: 50%;
        height: 40rem;
        // background: url('https://images.pexels.com/photos/461077/pexels-photo-461077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260') no-repeat center/cover;
        background-color: $light-2;

        .bg-tint{
            height: 100%;
            align-items: flex-start;
            justify-content: space-around;
        }

        .info-item{
            margin-left: 33%;
            align-items: flex-start;
        }

        i{
            font-size: 1.5rem;
            color: $primary-orange;
            margin-right: 0.4rem;
            width: 2rem;
        }

        h4{
            font-size: 1.7rem;
            color: $dark-1;
        }

        p{
            font-size: 1.2rem;
            font-weight: 500;
            color: $dark-2;
            margin-left: 2.5rem;

            a{color: $dark-2;}
        }
    }
    
    .form{
        background: url('../assets/img/kontakt/landing.jpeg') no-repeat center/cover;
        width: 50%;
        height: 40rem;
        justify-content: space-evenly;

        h3{
            font-size: 2.5rem;
            color: $light-1;
        }

        .form-item{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: $light-1;
            font-weight: 600;
            height: 3rem;
            width: calc(100% - 1.6rem);
            margin-bottom: 1rem;
            border-radius: 5px;
            padding: 0.8rem;
        }

        .fullname{
            justify-content: space-between;
            width: 100%;

            .form-item{
                width: 40%;
            }
        }

        .form-item span{
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        .form-item label{
            color: $dark-2;
            font-size: 1.1rem;
            overflow: hidden;
        }

        .form-item .error{
            color: red;
            font-size: 0.9rem;
            font-weight: 500;
        }

        .form-item input, .form-item textarea{
            font-size: 1rem;
            width: 100%;
            border: 0;
            background-color: $light-1;
            outline: none;
        }

        .textarea{
            height: 7rem;
            word-break: break-all;
        }

        .textarea textarea{
            resize: none;
        }

        .btn{
            align-self: flex-end;
        }

        .btn-orange:hover{
            cursor: pointer;
            background-color: #f4f4f4;
        }
    }

    .location{
        background-color: $light-grey-2;
        justify-content: center;
        padding: 5rem 0;

        .g-map{
            width: 50%;
            height: 50vh;
        }
    }


    //**********RESPONSIVE************//
    .laptopL{
        .info{
            height: 35rem;

            .info-item{
                margin-left: 30%;
            }

            i{
                font-size: 1.3rem;
                margin-right: 0.4rem;
                width: 1.8rem;
            }

            h4{
                font-size: 1.5rem;
            }

            p{
                font-size: 1.1rem;
                margin-left: 2.3rem;
            }
        }
        
        .form{
            height: 35rem;

            h3{
                font-size: 2.3rem;
            }

            .form-item{
                height: 2.7rem;
                width: calc(100% - 1.6rem);
                margin-bottom: 1rem;
                padding: 0.7rem;
            }

            .fullname{

                .form-item{
                    width: 40%;
                }
            }

            .form-item label{
                font-size: 1rem;
            }

            .form-item .error{
                font-size: 0.8rem;
            }

            .form-item input, .form-item textarea{
                font-size: 0.9rem;
            }

            .textarea{
                height: 6rem;
            }
        }

        .location{
            padding: 5rem 0;

            .g-map{
                width: 60%;
                height: 60vh;
            }
        }
    }

    .laptop{
        .info{
            width: 40%;
            height: 32rem;

            .info-item{
                margin-left: 15%;
            }

            i{
                font-size: 1.15rem;
                margin-right: 0.4rem;
                width: 1.5rem;
            }

            h4{
                font-size: 1.3rem;
            }

            p{
                font-size: 1rem;
                margin-left: 2rem;
            }
        }
        
        .form{
            width: 60%;
            height: 32rem;

            h3{
                font-size: 2rem;
            }

            .form-item{
                height: 2.5rem;
                width: calc(100% - 1.6rem);
                margin-bottom: 1rem;
                padding: 0.7rem;
            }

            .fullname{

                .form-item{
                    width: 42%;
                }
            }

            .form-item label{
                font-size: 0.9rem;
            }

            .form-item .error{
                font-size: 0.9rem;
            }

            .form-item input, .form-item textarea{
                font-size: 0.8rem;
            }

            .textarea{
                height: 5rem;
            }
        }

        .location{
            padding: 4rem 0;

            .g-map{
                width: 60%;
                height: 60vh;
            }
        }
    }

    .tablet{
        .contact{
            flex-direction: column;
            margin-top: 60px;
        }

        .info{
            width: 100%;
            height: auto;

            .bg-tint{                
                padding: 2rem 0;
            }

            .info-item{
                margin: 1rem 0 1rem 25%;
            }

            i{
                font-size: 1rem;
                margin-right: 0.4rem;
                width: 1.2rem;
            }

            h4{
                font-size: 1.15rem;
            }

            p{
                font-size: 0.9rem;
                margin-left: 1.7rem;
            }
        }
        
        .form{
            width: 100%;
            height: auto; 
            padding: 2rem 0;

            h3{
                font-size: 1.8rem;
                margin-bottom: 1rem;
            }

            .form-item{
                height: 2.5rem;
                width: calc(100% - 1.6rem);
                margin-bottom: 1rem;
                padding: 0.6rem;
            }

            .fullname{

                .form-item{
                    width: 42%;
                }
            }

            .form-item label{
                font-size: 0.85rem;
            }

            .form-item .error{
                font-size: 0.7rem;
            }

            .form-item input, .form-item textarea{
                font-size: 0.75rem;
            }

            .textarea{
                height: 4.5rem;
            }
        }

        .location{
            padding: 3rem 2rem;

            .g-map{
                width: 100%;
                height: 50vh;
            }
        }
    }

    .phone{
        .contact{
            flex-direction: column;
            margin-top: 60px;
        }

        .info{
            width: 100%;
            height: auto;

            .bg-tint{                
                padding: 1.5rem 0;
            }

            .info-item{
                margin: 0.7rem 0 0.7rem 20%;
            }

            i{
                font-size: 0.8rem;
                margin-right: 0.4rem;
                width: 1rem;
            }

            h4{
                font-size: 1.05rem;
            }

            p{
                font-size: 0.8rem;
                margin-left: 1.5rem;
            }
        }
        
        .form{
            width: 100%;
            height: auto; 
            padding: 1.5rem 0;

            h3{
                font-size: 1.5rem;
                margin-bottom: 0.7rem;
            }

            .form-item{
                height: 2.5rem;
                width: calc(100% - 1.6rem);
                margin-bottom: 1rem;
                padding: 0.6rem;
            }

            .fullname{
                flex-direction: column;

                .form-item{
                    width: calc(100% - 1.6rem);
                }
            }

            .form-item label{
                font-size: 0.7rem;
            }

            .form-item .error{
                font-size: 0.65rem;
            }

            .form-item input, .form-item textarea{
                font-size: 0.65rem;
            }

            .textarea{
                height: 4rem;
            }
        }

        .location{
            padding: 2rem 1.5rem;

            .g-map{
                width: 100%;
                height: 50vh;
            }
        }
    }

    .phoneS{
        .contact{
            flex-direction: column;
            margin-top: 60px;
        }

        .info{
            width: 100%;
            height: auto;

            .bg-tint{                
                padding: 1rem 0;
            }

            .info-item{
                margin: 0.7rem 0 0.7rem 20%;
            }

            i{
                font-size: 0.7rem;
                margin-right: 0.4rem;
                width: 1rem;
            }

            h4{
                font-size: 0.95rem;
            }

            p{
                font-size: 0.7rem;
                margin-left: 1.5rem;
            }
        }
        
        .form{
            width: 100%;
            height: auto; 
            padding: 1rem 0;

            h3{
                font-size: 1.3rem;
                margin-bottom: 0.7rem;
            }

            .form-item{
                height: 2.5rem;
                width: calc(100% - 2rem);
                margin-bottom: 1rem;
                padding: 0.6rem;
            }

            .fullname{
                flex-direction: column;

                .form-item{
                    width: calc(100% - 2rem);
                }
            }

            .form-item label{
                font-size: 0.7rem;
            }

            .form-item .error{
                font-size: 0.6rem;
            }

            .form-item input, .form-item textarea{
                font-size: 0.65rem;
            }

            .textarea{
                height: 4rem;
            }

            .btn{
                margin-right: 1rem;
            }
        }

        .location{
            padding: 1.5rem 1rem;

            .g-map{
                width: 100%;
                height: 50vh;
            }
        }
    }

</style>