<template>
    <div class="navbar">

        <navbar-top v-if="isLargeScreen"></navbar-top>

        <navbar-bottom></navbar-bottom>

    </div>
</template>

<script>
    import NavbarTop from './NavbarTopComponent.vue'
    import NavbarBottom from './NavbarBottomComponent.vue'

    export default {
        name: 'navbar',
        components: {
            'navbar-top' : NavbarTop,
            'navbar-bottom' : NavbarBottom
        },

        computed: {
            isLargeScreen(){
                if(this.$mq === 'desktop' || this.$mq === 'laptopL' || this.$mq === 'laptop' )
                    return true

                return false
            }
        },
    }
</script>

<style lang="scss" scoped>

    

</style>