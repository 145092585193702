<template>
    <div :class="$mq">



        <div class="navbar-bottom flex-row">


            <router-link tag="li" to="/" class="navbar-logo flex-column">
                <img src="../../assets/img/logo/logo-univerzal.png" alt="Univerzal komerc logo">
            </router-link>


            <ul class="navigation flex-row" v-if="isLargeScreen">
                <router-link tag="li" to="/">
                    <a href="#">Home</a>
                </router-link>

                <li @mouseenter="menuUpDown()" @mouseleave="menuUpDown()">
                    <router-link tag="li" to="/prodaja/">
                        <a href="#">Vakuum pumpe</a>
                        <dropdown-pumpe :class="show_dropdown ? 'dropdown-pumpe show-dropdown' : 'dropdown-pumpe'"></dropdown-pumpe>
                    </router-link>
                </li>            

                <router-link tag="li" to="/lamele/">
                    <a href="#">Grafitne lamele</a>
                </router-link>

                <router-link tag="li" to="/kontakt/">
                    <a href="#">Kontakt</a>
                </router-link>
            </ul>


            <div class="menu-burger" v-if="!isLargeScreen" @click="mobileMenu()">
                <div class="line1"></div>
                <div class="line2"></div>
                <div class="line3"></div>
            </div>

        </div>



        <div class="mobile-menu flex-row" v-if="!isLargeScreen">


            <ul class="mobile-menu-main flex-column">                
                <router-link tag="li" to="/">
                    <a>Home</a>
                </router-link>

                <li @click="expandPumpe()">
                    <a>Vakuum Pumpe</a>
                </li>

                <router-link tag="li" to="/lamele/">
                    <a>Grafitne Lamele</a>
                </router-link>

                <router-link tag="li" to="/kontakt/">
                    <a>Kontakt</a>
                </router-link>
            </ul>


            <ul class="mobile-pumpe-dropdown flex-column">

                <li>
                    <i class="fas fa-arrow-left" @click="expandPumpe()"></i>
                </li>

                <router-link tag="li" to="/princip-rada/">
                    <a>Princip rada</a>
                </router-link>

                <router-link tag="li" to="/prodaja/">
                    <a>Prodaja</a>
                </router-link>

                <router-link tag="li" to="/aplikacije/">
                    <a>Aplikacije</a>
                </router-link>

                <router-link tag="li" to="/servis/">
                    <a>Servis</a>
                </router-link>

                <router-link tag="li" to="/rezervnidelovi/svi-delovi/">
                    <a>Rezervni delovi</a>
                </router-link>

            </ul>


        </div>



    </div> 
</template>

<script>
    import DropdownPumpe from './DropdownPumpeComponent.vue'

    export default {
        name: 'navbarBottom',
        components: {
            'dropdown-pumpe': DropdownPumpe
        },

        data(){
            return{
                show_dropdown: false
            }
        },

        computed: {
            isLargeScreen(){
                if(this.$mq === 'desktop' || this.$mq === 'laptopL' || this.$mq === 'laptop' )
                    return true

                return false
            }
        },

        methods: {
            menuUpDown(){
                this.show_dropdown = !this.show_dropdown                
            },

            mobileMenu(){
                const burger = document.querySelector('.menu-burger');
                const menu = document.querySelector('.mobile-menu');                
                const ul_main = document.querySelector(".mobile-menu-main");
                const ul_pumpe = document.querySelector(".mobile-pumpe-dropdown");

                menu.classList.toggle('menu-active');

                burger.classList.toggle('active');
                
                if(ul_main.classList.contains('translateX-main')){
                    ul_main.classList.toggle('translateX-main');
                }
                if(ul_pumpe.classList.contains('translateX-pumpe')){
                    ul_pumpe.classList.toggle('translateX-pumpe');
                }
            },

            expandPumpe(){                
                const ul_main = document.querySelector(".mobile-menu-main");
                const ul_pumpe = document.querySelector(".mobile-pumpe-dropdown");
                
                ul_main.classList.toggle('translateX-main');
                ul_pumpe.classList.toggle('translateX-pumpe');
            }
        },

        watch:{
            $route (to, from){                
                const menu = document.querySelector('.mobile-menu');            
                if(menu.classList.contains('menu-active')){
                    this.mobileMenu()
                }
            }
        } 
    }
</script>

<style lang="scss" scoped>
    *{
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .navbar-bottom{
        z-index: 1000;  
        background-color: $light-1;
        height: 85px;
        width: 100%;
        padding: 0 3%;
        box-sizing: border-box;
        border-bottom: 1px solid $light-2;
    }

    .navbar-logo{
        cursor: pointer;
        justify-content: center;
        height: 100%;

        img{
            height: 70%;
        }
    }

    .navigation{
        margin-left: 5rem;
    }

    .dropdown-pumpe{
        transform: translateX(200%);

        &.show-dropdown{
            transform: translateX(0);
        }
    }

    .navigation li{
        cursor: pointer;
        display: inline;
        margin-left: 2rem;
        padding: 100% 1rem;
    }

    .navigation li a{
        cursor: pointer;
        color: $dark-2;
        font-size: 1.1rem;
        font-weight: 600;
        padding: 100% 0;
    }

    .navigation li:hover{
        a{
            color: $primary-orange;
        }        
    }

    .dropdown-hover{
        display: grid;
    }


//**********MOBILE MENU************//
    .mobile-menu{    
        z-index: 1000;   
        position: fixed;
        top: 60px;
        height: 40vh;
        width: 100%;
        padding: 2rem 0;
        box-sizing: border-box;
        background-color: $light-2;
        border-bottom: 1px solid $light-3;
        transform: translateX(100%);
        transition: all 0.5s ease-in-out;
    }

    .mobile-menu ul{
        z-index: 1000;
        width: 50%;
        transition: all 0.5s ease-in-out;
    }

    .mobile-menu-main{
        z-index: 1000;
        height: 100%;
        justify-content: space-around;
        transform: translateX(50%);
    }

    .mobile-pumpe-dropdown{
        z-index: 1000;
        width: 100%;
        height: 100%;
        justify-content: space-around;
        transform: translateX(100%);
    }

    .mobile-pumpe-dropdown i{
        cursor: pointer;
        font-size: 1.3rem;
        color: $primary-orange;
    }

    .mobile-menu li{        
        text-align: center;
        width: 100%;
    }

    .mobile-menu a{
        cursor: pointer;
        letter-spacing: 1px;
        font-weight: 600;
        font-size: 0.9rem;
        color: $dark-2;
    }

    .mobile-menu a:hover{
        color: $primary-orange;
    }

    .menu-active{
        transform: translateX(0%);
    }

    .menu-burger{
        align-self: center;
        margin-right: 3%;
        cursor: pointer;
        z-index: 1000;
    }

    .menu-burger div{
        width: 25px;
        height: 3px;
        margin: 5px;
        background-color: $text-primary;
        transition: all 0.3s ease-in-out;
    }

    .active .line1{
        transform: rotate(-45deg) translate(-5px,6px);    
    }

    .active .line2{
        opacity: 0;    
    }

    .active .line3{
        transform: rotate(45deg) translate(-5px,-6px);    
    }

    .translateX-main{
        transform: translateX(-100%);
    }

    .translateX-pumpe{
        transform: translateX(-50%);
    }


    //**********RESPONSIVE************//
    .laptopL{     

        .navbar-logo{

            img{
                height: 60%;
            }
        }   

        .navigation{            
            margin-left: 4rem;

            li{
                margin-left: 1rem;

                a{
                    font-size: 1rem;
                }
            }
        }
    }

    .laptop{     
        .navbar-bottom{
            height: 75px;
        }

        .navbar-logo{

            img{
                height: 60%;
            }
        }   

        .navigation{            
            margin-left: 3rem;

            li{
                margin-left: 0.5rem;

                a{
                    font-size: 0.9rem;
                }
            }
        }
    }

    .tablet{
        .navbar-bottom{
            position: fixed;
            top: 0;
            justify-content: space-between;
            height: 60px;

            .navbar-logo img{
                height: 60%;
            }
        }     
    }

    .phone, .phoneS{
        .navbar-bottom{
            position: fixed;
            top: 0;
            justify-content: space-between;
            height: 60px;

            .navbar-logo img{
                height: 60%;
            }
        }    

        .mobile-menu{            
            padding: 1rem 0;

            a{
                font-size: 0.75rem;
            }

            .mobile-pumpe-dropdown i{
                font-size: 0.9rem;
            }
        } 
    }
</style>