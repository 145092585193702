<template>
    <main class="flex-column" :class="$mq">

        <top></top>

        <info></info>

        <lamele></lamele>

    </main>    
</template>

<script>
    import Top from '@/components/home/HomeTopComponent1.vue'
    import Lamele from '@/components/home/HomeLameleComponent.vue'
    import Info from '@/components/home/HomeInfoComponent.vue'

    export default {
        name: 'home',
        metaInfo: {
            title: 'Univerzal komerc',
            titleTemplate: null,
            meta: [
                {name: 'description', content: 'Bavimo se prodajom i servisom vakuum pumpi, kao i prodajom rezervnih delova za vakuum pumpe. Ovlašćeni Busch uvoznik i distributer.'}
            ],
            link: [
                {rel: 'canonical', href: 'https://www.vakumpumpe.com/'}
            ]
        },

        components: {
            'top': Top,
            'info': Info,
            'lamele': Lamele,
        }
    }

</script>