<template>
    <router-link tag="a" :to="`/princip-rada/${this.tehnologija.route_param}/`" :class="$mq" class="tehnologija-small flex-row bg-light">

        <h2>{{this.tehnologija.title}}</h2>  
        
        <div class="icon flex-column">
            <img :src="`${icon()}`" :alt="`${this.tehnologija.title} crtez`">
        </div>

        <div class="info flex-column">
            <div class="items flex-column">                    
                <router-link tag="a" class="flex-row" v-for="(serija, index) in this.serije" :key="index" :to="`/prodaja/${serija.route_param}/`">
                    <i class="fas fa-plus"></i>
                    <span>{{serija.title}}</span>
                </router-link>                    
            </div>
        </div>

    </router-link>
</template>

<script>
    export default {
        name: 'tehnologija-small',

        props: [
            'tehnologija', 'index'
        ],

        data(){
            return{
                serije: []
            }
        },

        mounted(){
            this.fetch("series", "technology", this.tehnologija.id).then((response) => {
                this.serije = response.data.data;
            })
        },

        methods:{
            icon(){
                return require("../../assets/img/tehnologija/icon/"+this.tehnologija.icon)
            },     
        }
    }
</script>

<style lang="scss" scoped>
    .tehnologija-small{
        background-color: $light-1;
        width: 90%;
        padding: 2rem 2.5rem;
        margin-bottom: 2rem;
        box-sizing: border-box;
        border-radius: 5px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        h2{
            flex: 1;
            color: $dark-1;
            font-size: 2rem;
        }

        .icon{
            flex: 1;

            img{
                height: 8rem;
            }
        }

        .info{
            flex: 1;

            .items{

                a{
                    text-transform: uppercase;
                    color: $dark-2;
                    font-size: 1.5rem;
                    font-weight: 600;
                    padding: 0.5rem;
                    transition: 0.3s ease-in-out;

                    i{
                        color: $primary-orange;
                        font-size: 1.1rem;
                        margin-right: 0.5rem;
                        transition: 0.3s ease-in-out;
                    }

                    span{
                        transition: 0.3s ease-in-out;
                    }

                    &:hover{
                        background-color: #e95201;

                        i{
                            transform: scale(1.3);
                        }
                    }
                }
            }
        }

        &:hover{
            background-color: $primary-orange;

            h2{
                color: $light-1
            }

            .info{

                .items{

                    a{

                        i{
                            color: $dark-1;
                        }

                        span{
                            color: $light-1;
                        }
                    }
                }
            }
        }
    }


    //**********RESPONSIVE************//
    .laptopL{
        padding: 1.5rem 2rem;

        h2{
            font-size: 1.8rem;
        }

        .icon{

            img{
                height: 6rem;
            }
        }

        .info{

            .items{

                a{
                    font-size: 1.3rem;

                    i{
                        font-size: 1rem;
                    }
                }
            }
        }
    }


    .laptop{
        padding: 1.3rem 1.8rem;

        h2{
            font-size: 1.5rem;
        }

        .icon{

            img{
                height: 5rem;
            }
        }

        .info{

            .items{

                a{
                    font-size: 1.1rem;

                    i{
                        font-size: 0.95rem;
                    }
                }
            }
        }
    }


    .tablet{
        padding: 1rem;

        h2{
            font-size: 1.2rem;
        }

        .icon{

            img{
                height: 4rem;
            }
        }

        .info{

            .items{

                a{
                    font-size: 0.95rem;

                    i{
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }


    .phone{
        padding: 1rem;

        h2{
            font-size: 1rem;
        }

        .icon{

            img{
                height: 3rem;
            }
        }

        .info{

            .items{

                a{
                    font-size: 0.8rem;
                    padding: 0.3rem;

                    i{
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }


    .phoneS{
        flex-direction: column;
        padding: 1rem;

        h2{
            text-align: center;
            font-size: 1rem;
            margin-bottom: 1rem
        }

        .icon{
            margin-bottom: 1rem;

            img{
                height: 3.5rem;
            }
        }

        .info{

            .items{

                a{
                    font-size: 0.8rem;
                    padding: 0.3rem;

                    i{
                        font-size: 0.7rem;
                    }
                }
            }
        }
    }
</style>