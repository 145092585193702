<template>
    <main class="flex-column">

        <h5>{{this.pumpa.name}}</h5>

        <img :src="`${img()}`" alt="`${this.pumpa.name} slika`">

        <p v-html="this.pumpa.speed"></p>
        <p v-html="this.pumpa.pressure"></p>

        <a :href="`${this.pumpa.link}`" class="btn btn-orange" target="_blank"><i class="fas fa-external-link-alt"></i> Detalji</a>

    </main>
</template>

<script>
    export default {
        name: 'pumpa',

        props: [
            'pumpa'
        ],

        data(){
            return{
                pumpe: []
            }
        },

        methods: {
            img(){
                return require("../../assets/img/pumpe/thumbnail/"+this.pumpa.img)
            },   

        }
    }
</script>

<style lang="scss" scoped>
    main{
        height: 100%;
        padding: 2rem 0;
        box-sizing: border-box;
        justify-content: space-between;
    }

    h5{
        text-align: center;
        font-size: 1.3rem;
    }

    img{
        width: 40%;
        height: auto;
        margin: 1rem 0;
    }

    p{
        font-size: 1.1rem;
        font-weight: 600;
        color: $dark-4;
    }

    .btn{
        justify-self: flex-end!important;
        margin-top: 0.7rem;

        i{
            font-size: 0.7rem;
        }
    }

    //**********RESPONSIVE************//
    .laptopL{
        main{
            padding: 2rem 0;

            h5{
                font-size: 1.2rem;
            }

            p{
                font-size: 1.05rem;
            }

        }
    }
    
    .laptop{
        main{
            padding: 1.5rem 0;

            h5{
                font-size: 1.15rem;
            }

            img{
                width: 45%;
                margin: 0.7rem 0;
            }

            p{
                font-size: 1rem;
            }

        }
    }
    
    .tablet{
        main{
            padding: 1.5rem 0;

            h5{
                font-size: 1.1rem;
            }

            img{
                width: 45%;
                margin: 0.7rem 0;
            }

            p{
                font-size: 0.9rem;
            }

        }
    }
    
    .phone{
        main{
            padding: 0.7rem 0;

            h5{
                font-size: 0.85rem;
            }

            img{
                width: 50%;
                margin: 0.7rem 0;
            }

            p{
                font-size: 0.75rem;
            }

        }
    }
    
    .phoneS{
        main{
            padding: 0.7rem 0;

            h5{
                font-size: 0.8rem;
            }

            img{
                width: 35%;
                margin: 0.7rem 0;
            }

            p{
                font-size: 0.7rem;
            }

        }
    }
</style>