<template>
    <main class="bg-light flex-column" >



        <div class="tehnologije flex-column">

            <h4>Prodaja vakuum pumpi</h4>

            <p>Busch proizvodi podeljeni su u serije, grupisane na osnovu principa rada po kojima funkcionišu</p>

            <div class="tehnologije-items grid-2">            
                <tehnologija-small v-for="(tehnologija, index) in tehnologije" :key="tehnologija.id" :tehnologija="tehnologija" :id="`${tehnologija.route_param}`" :index="index">   </tehnologija-small>
            </div>

            <router-link to="/prodaja/" class="btn btn-orange">Više</router-link>

        </div>



        <div class="aplikacije">
            <div class="wrapper-tint flex-column">

                <h4>Mnoge grane industrije zahtevaju kvalitetan i pouzdan izvor vakuuma</h4>

                <p>Busch nudi širok izbor proizvoda, različitih karakteristika, dizajniranih da se uklope u potrebe raznovrsnih aplikacija</p>

                <div class="aplikacije-list grid-4">
                    <router-link :to="`/aplikacija/${aplikacija.route_param}/`" class="aplikacije-item flex-column" v-for="aplikacija in aplikacije" :key="aplikacija.id">
                        <i :class="aplikacija.icon"></i>
                        <div class="item-title flex-column">
                            <p>{{aplikacija.title}}</p>
                        </div>
                    </router-link>
                </div>

                <router-link to="/aplikacije/" class="btn btn-light">Više</router-link>

            </div>
        </div>



    </main>
</template>

<script>
    import TehnologijaSmall from '@/components/home/TehnologijaHomeSmallComponent.vue';

    export default {
        name: 'home-info',

        components: {
            'tehnologija-small': TehnologijaSmall,
        },

        data(){
            return{
                aplikacije: [],
                tehnologije: []
            }
        },

        mounted(){
            this.fetch("applications").then((response) => {
                this.aplikacije = response.data.data;
            })

            this.fetch("technologies").then((response) => {
                this.tehnologije = response.data.data;
            })    
        },

        methods: {
            icon(img_url, index){
                if(this.$mq == 'desktop' || this.$mq == 'laptopL' || this.$mq == 'laptop'){                    
                    if( (index + 1) % 8 == 2 || (index + 1) % 8 == 4 || (index + 1) % 8 == 5 || (index + 1) % 8 == 7)
                        return require("../../assets/img/tehnologija/icon/"+"dark-"+img_url)
                    else
                        return require("../../assets/img/tehnologija/icon/"+img_url)
                }else if(this.$mq == 'tablet'){                      
                    if( (index + 1) % 2 == 0)
                        return require("../../assets/img/tehnologija/icon/"+"dark-"+img_url)
                    else
                        return require("../../assets/img/tehnologija/icon/"+img_url)
                }else{                      
                    if( (index + 1) % 4 == 2 || (index + 1) % 4 == 3)
                        return require("../../assets/img/tehnologija/icon/"+"dark-"+img_url)
                    else
                        return require("../../assets/img/tehnologija/icon/"+img_url)
                }
            }, 
        }
    }
</script>

<style lang="scss" scoped>
    main{
        width: 100%;
        margin-top: 1rem;
    }    

    .tehnologije{
        width: 100%;      
        align-items: center;
        padding: 2rem 3rem;
        box-sizing: border-box;

        h4{            
            text-align: center;
            font-size: 2.5rem;
        }

        p{
            text-align: center;
            color: $dark-2;
            font-size: 1.3rem;
            font-weight: 600;
            margin-top: 0.5rem;
        }        

        .tehnologije-items{
            width: 100%;
            padding: 2rem 3rem;
            grid-gap: 0.5rem;
        }
    }    

    .aplikacije{
        width: 100%;
        background: url('../../assets/img/home/home-aplikacije.jpg') no-repeat center/cover;

        .wrapper-tint{
            height: 100%;
            width: 100%;  
            padding: 2rem 3rem;
            background-color: rgba(31, 30, 30, 0.85);    
            box-sizing: border-box;

            h4{   
                text-align: center;
                color: $primary-orange;         
                font-size: 2.5rem;
            }

            p{
                text-align: center;
                color: $light-3;    
                font-size: 1.3rem;
                font-weight: 600;
                margin-top: 0.5rem;
            }

            .aplikacije-list{
                text-align: center;
                width: 100%;
                margin-top: 2rem;
                grid-row-gap: 0.5rem;

                .aplikacije-item{
                    position: relative;
                    width: fit-content;
                    padding: 2rem;
                    margin: 0 auto;

                    i{            
                        color: $light-3;          
                        font-size: 1.6rem;
                        opacity: 1;
                        transition: 0.3s ease-in-out;
                    }

                    .item-title{
                        position: absolute;
                        top: 0;
                        justify-content: center;
                        width: 100%;
                        height: 100%;

                        p{
                            color: $light-1;
                            font-size: 1.2rem;
                            font-weight: 700;
                            margin: 0;
                            opacity: 0;
                            transition: 0.3s ease-in-out;
                        }
                    }

                    &:hover{
                        i{
                            color: $primary-orange;
                            transform: translateX(-2.3rem);
                        }

                        p{
                            transform: translateX(2.3rem);
                            opacity: 1;
                            margin-left: 2rem;
                        }
                    }
                }
            }

            .btn{
                margin-top: 2rem;
            }
        }
    }


    //**********RESPONSIVE************//
    .laptopL{

        .tehnologije{            
            h4{font-size: 2rem;}
            p{font-size: 1.2rem;}

            .tehnologije-list{        
                grid-template-columns: repeat(4, 1fr); 
                margin-top: 1.5rem;
                grid-row-gap: 0.7rem;

                .tehnologije-list-item{
                    width: calc(100% - 0.7rem);
                }
            }
        }

        .aplikacije{
            h4{font-size: 2rem;}
            p{font-size: 1.2rem;}

            .aplikacije-list{
                margin-top: 1.5rem;

                .aplikacije-item{
                    padding: 1.5rem;

                    i{            
                        font-size: 1.6rem;
                    }

                    .item-title{

                        p{
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
    }

    .laptop{

        .tehnologije{      
            padding: 1.5rem 2rem;
            
            h4{font-size: 1.8rem;}
            p{font-size: 1.1rem;}

            .tehnologije-list{        
                grid-template-columns: repeat(4, 1fr); 
                margin-top: 1.5rem;
                grid-row-gap: 0.6rem;

                .tehnologije-list-item{
                    width: calc(100% - 0.6rem);

                    .title{
                        font-size: 1.3rem;
                    }     

                    .details{
                        font-size: 1rem;
                        padding: 0.5rem 0.7rem;

                        i{
                            font-size: 0.9rem;
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }

        .aplikacije{
            h4{font-size: 1.8rem;}
            p{font-size: 1.1rem;}

            .wrapper-tint{                
                padding: 1.5rem 2rem;
            }

            .aplikacije-list{
                margin-top: 1.5rem;

                .aplikacije-item{
                    padding: 1.5rem;

                    i{            
                        font-size: 1.35rem;
                    }

                    .item-title{

                        p{
                            font-size: 1rem;
                        }
                    }

                    &:hover{
                        i{
                            transform: translateX(-2rem);
                        }

                        p{
                            transform: translateX(2rem);
                        }
                    }
                }
            }
        }
    }

    .tablet{

        .tehnologije{      
            width: 100%;
            padding: 1.5rem 2rem;
            
            h4{font-size: 1.5rem;}
            p{font-size: 1rem;}           

            .tehnologije-items{
                grid-template-columns: repeat(1, 1fr);
                padding: 1.5rem;
            }

            .btn{
                margin-top: 1rem;
            }
        }

        .aplikacije{
            h4{font-size: 1.5rem;}
            p{font-size: 1rem;}

            .wrapper-tint{                
                padding: 1.5rem 2rem;
            }

            .aplikacije-list{
                margin-top: 1rem;

                .aplikacije-item{
                    padding: 1.3rem;

                    i{            
                        font-size: 1.2rem;
                    }

                    .item-title{

                        p{
                            font-size: 0.95rem;
                        }
                    }

                    &:hover{
                        i{
                            transform: translateX(-1.5rem);
                        }

                        p{
                            transform: translateX(1.5rem);
                        }
                    }
                }
            }
        }
    }

    .phone{

        .tehnologije{      
            padding: 1rem;
            
            h4{font-size: 1.3rem;}
            p{
                font-size: 0.9rem;
                margin-top: 0.2rem;
            }

            .tehnologije-items{
                grid-template-columns: repeat(1, 1fr);
                padding: 1.2rem;
            }

            .btn{
                margin-top: 0.7rem;
            }
        }

        .aplikacije{

            h4{font-size: 1.3rem;}
            p{
                font-size: 0.9rem;
                margin-top: 0.2rem;
            }

            .wrapper-tint{                
                padding: 1rem;
            }

            .aplikacije-list{
                margin-top: 1rem;

                .aplikacije-item{
                    padding: 1rem;

                    i{            
                        font-size: 0.9rem;
                    }

                    .item-title{

                        p{
                            font-size: 0.8rem;
                        }
                    }

                    &:hover{
                        i{
                            transform: translateX(0);
                        }

                        p{
                            display: none;
                        }
                    }
                }
            }

            .btn{
                margin-top: 0.7rem;
            }
        }
    }

    .phoneS{

        .tehnologije{      
            padding: 1rem ;
            
            h4{font-size: 1.1rem;}
            p{
                font-size: 0.8rem;
                margin-top: 0.2rem;
            }

            .tehnologije-items{
                grid-template-columns: repeat(1, 1fr);
                padding: 1rem;
            }

            .btn{
                margin-top: 0.7rem;
            }
        }

        .aplikacije{

            h4{font-size: 1.1rem;}
            p{
                font-size: 0.8rem;
                margin-top: 0.2rem;
            }

            .wrapper-tint{                
                padding: 1rem;
            }

            .aplikacije-list{
                margin-top: 1rem;

                .aplikacije-item{
                    padding: 0.7rem;

                    i{            
                        font-size: 0.9rem;
                    }

                    .item-title{

                        p{
                            font-size: 0.75rem;
                        }
                    }

                    &:hover{
                        i{
                            transform: translateX(0);
                        }

                        p{
                            display: none;
                        }
                    }
                }
            }

            .btn{
                margin-top: 0.7rem;
            }
        }
    }
</style>