<template>
    <ul class="flex-column">

        <router-link tag="li" v-for="tehnologija in tehnologije" :key="tehnologija.id" :to="`/princip-rada/${tehnologija.route_param}/`">
            <a href="#">{{tehnologija.title_short}}</a>
        </router-link>
        
    </ul>
</template>

<script>
    export default {
        name: 'expand-tehnologije',

        data(){
            return{
                tehnologije: []
            }
        },

        mounted(){
            this.fetch("technologies").then((response) => {
                this.tehnologije = response.data.data;
            })    
        },

        methods: {
            
        }
    }
</script>

<style lang="scss" scoped>
    ul{
        height: 100%;
        justify-content: space-around;
        align-items: flex-start;

        li{
            a{
                display: block;
                color: $dark-4;
                font-size: 1rem;
                font-weight: 600;
            }

            a::first-letter{
                text-transform: capitalize
            }

            a:hover{
                color: $primary-orange!important;
            }
        }
    }

    //**********RESPONSIVE************//
    .laptopL{
        a{
            font-size: 0.9rem;
        }
    }

    .laptop{
        a{
            font-size: 0.8rem;
        }
    }
</style>